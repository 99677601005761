import {getNotificationAgent} from "../workers/NotificationAgent";
import {getNotifiedSchedules} from "../common/NotifiedSchedules";
import {getNotificationView} from "../common/NotificationView";
import {getArrivedVehicles} from "../common/ArrivedVehicles";

export const TAB_INDEX = 'system/tabIndex';
export const POINT_ID = "system/pointId";
export const IS_LOADING = "system/isLoading";
export const POINT_LIST = "system/pointList";

export function setTabIndex(tabIndex){
    return dispatch =>{
        dispatch(
            {
                type : TAB_INDEX,
                tabIndex : tabIndex
            }
        )
    }
}

export function setPointId(pointId){
    localStorage.setItem('point_id',pointId)
    getNotificationAgent().applyChange();
    getNotifiedSchedules().initialize();
    getArrivedVehicles().initialize();
    getNotificationView().initialize();
    return dispatch =>{
        dispatch(
            {
                type : POINT_ID,
                pointId : pointId
            }
        )
    }
}

export function setIsLoading(isLoading){
    return dispatch =>{
        dispatch(
            {
                type : IS_LOADING,
                isLoading : isLoading
            }
        )
    }
}

export function setPointList(pointList){
    
    return dispatch =>{
        dispatch({
            type : POINT_LIST,
            pointList : pointList
        });
    }
}