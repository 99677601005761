export const IS_LOGGED_IN = 'user/isLoggedIn';
export const ENABLE_RUN_VIEW = 'user/enableRunView';

export function updateLoginStatus(){
    return dispatch =>{
        const jwt = localStorage.getItem('eva_calls_jwt')
        const is_LoggedIn = jwt ? true : false;
        dispatch(
            {
                type : IS_LOGGED_IN,
                is_LoggedIn : is_LoggedIn
            }
        )
    }
}

export function updateRunViewStatus(){
    return dispatch =>{
        const auth = localStorage.getItem('enable_run_view')
        const enableRunView = auth == 1 ? true : false;
        dispatch(
            {
                type : ENABLE_RUN_VIEW,
                enableRunView : enableRunView
            }
        )
    }
}