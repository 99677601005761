import React from 'react';
import { connect } from 'react-redux';
import './CallCompletePage.css';
import { withRouter } from 'react-router';
import { setIsLoading } from '../../actions/systemAction';
import RequestClient from '../../common/requestClient';
import Loading from '../../components/Loading';
import GreenButton from '../../components/buttons/GreenButton';
import {SuccessMsg,CautionMsg} from '../../components/SimpleMsges';
import {getAreaMap} from "../../common/AreaMap";
import {areaMapVersionIdChanged} from "../../common/AreaMapUtil";


const STATUS_API_CALLING = 0
const STATUS_REGIST_RESERVE = 1
const STATUS_REGIST_SCHEDULE = 2

function Reservation(props) {
    return(
        <div className='call-complete-reservation-root content-root'>
            <CautionMsg buttonName="TOPへ" onClick={props.handleTop} message={`空き車両がないため\n呼び出し予約をしました`}>
                <GreenButton buttonName="予約確認" onClick={props.handleReservation}/>
            </CautionMsg>
        </div>
    )
}

function toDoubleDigits(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
   return num;     
  };

function Schedule(props) {
    var date = new Date(props.schedule.plan_start_time)
    date.setMinutes(date.getMinutes() + 3)
    date.setHours(date.getHours() + 9)
    const time = toDoubleDigits(date.getHours()) + ":" + toDoubleDigits(date.getMinutes());
    return (
        <div className='call-complete-schedule-root content-root'>
            <SuccessMsg buttonName="TOPへ" onClick={props.handleTop} message={props.schedule.vehicle_name+`を\nスケジュール登録しました`}>
                <div className="call-schedule-time-frame">
                    <div className="call-schedule-time-label">
                        到着目安
                    </div>
                    <div className="call-schedule-time">{time}</div>
                </div>
                <GreenButton buttonName="スケジュール確認" onClick={props.handleSchedule}/>
            </SuccessMsg>
        </div>
    )
}
class CallCompletePage extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            status: STATUS_API_CALLING,
            intervalId: -1,
            reservation: {},
            schedule: {}
        }
        this.handleTop = this.handleTop.bind(this)
        this.handleReservation = this.handleReservation.bind(this)
        this.handleSchedule = this.handleSchedule.bind(this)
    }
    
    componentDidMount() {
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/call/confirm' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        } else {
            this.registReservation()
        }

    }

    componentWillUnmount() {
        this.stopInterval()
        this.props.dispatchSetIsLoading(false)
    }

    registReservation() {

        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)

        const url = "/api/reservations"
        const data = {
            pickup_point_id: this.props.pointId,
            dropoff_point_id: this.props.destinationPointId,
            area_map_version_id: getAreaMap().getVersionId()
        };
        RequestClient.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                }
                if (response.status === 409) {
                    areaMapVersionIdChanged();
                }
                if(response.status >= 400) {
                    this.props.dispatchSetIsLoading(false)
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if (data) {
                    if (data.result.reserve_exist_flg == 1) {
                        this.setState({ status: STATUS_REGIST_RESERVE })
                        this.props.dispatchSetIsLoading(false)
                    } else {
                        if (data.result.is_regist_check == 1) {
                            if (data.result.is_active == 0) {
                                this.setState({ schedule: data.result, status: STATUS_REGIST_SCHEDULE })
                            } else {
                                this.setState({ status: STATUS_REGIST_RESERVE })
                            }
                            this.props.dispatchSetIsLoading(false)
                        } else {
                            const intervalId = setInterval(() => this.checkScedule(), 5000)
                            this.setState({ intervalId: intervalId, reservation: data.result })
                        }
                    }
                }
            })
    }

    checkScedule() {
        if (this.state.status == 0) {
            const url = "/api/reservation/" + this.state.reservation.reservation_id
            RequestClient.get(url)
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else if(response.status >= 400){
                        this.props.dispatchSetIsLoading(false)
                        this.props.history.push({ pathname: "/error", state: {status: response.status} })
                    } 
                })
                .then(data => {
                    if (data) {
                        if (data.result.is_regist_check == 1) {
                            if (data.result.is_active == 0) {
                                this.setState({ schedule: data.result, status: STATUS_REGIST_SCHEDULE })
                            } else {
                                this.setState({ status: STATUS_REGIST_RESERVE })
                            }
                            this.stopInterval()
                            this.props.dispatchSetIsLoading(false)
                        }
                    }
                }
                )
        }
    }

    stopInterval() {
        if (this.state.intervalId > -1) {
            clearInterval(this.state.intervalId)
        }
    }
    handleTop() {
        this.props.history.push('/')
    }
    handleReservation() {
        this.props.history.push('/reservations')
    }
    handleSchedule() {
        this.props.history.push('/schedules')
    }

    render(){
        var component = ""
        if (this.state.status == 0) {
            component =<div className='content-root'><Loading/></div>
        }
        else if(this.state.status == 1){
            component =<Reservation handleReservation={this.handleReservation} handleTop={this.handleTop}/>
        }
        else if(this.state.status == 2){
            component =<Schedule schedule={this.state.schedule} handleSchedule={this.handleSchedule} handleTop={this.handleTop}/>
        }
        return(
            <>
            {component}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        pointId: state.systemReducer.pointId,
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CallCompletePage));