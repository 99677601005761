import React from 'react';
import {withRouter} from 'react-router';
import BackButton from '../../../components/BackButton';
import "./SettingsNotificationPage.css";
import NotificationForVehicles from "../../../components/notifications/NotificationForVehicles";
import {areaMapVersionIdChanged, checkAreaMapVersionId} from "../../../common/AreaMapUtil";

class SettingsNotificationPage extends React.Component {
    constructor(props) {
        super(props)
        this.handleSettingsMenu = this.handleSettingsMenu.bind(this)
    }

    componentDidMount(){
        checkAreaMapVersionId(() => {
            areaMapVersionIdChanged();
            this.props.history.push({ pathname: "/error", state: {status: 409} })
        }, (response) => {
            this.props.history.push({ pathname: "/error", state: {status: response.status} })
        })
    }

    handleSettingsMenu() {
        this.props.history.push("/settings")
    }

    render() {
        return (
            <div className='settings-notification-root content-root'>
                <div className="settings-notification-page">
                    <BackButton handleBack={this.handleSettingsMenu}/>
                    <div className="settings-notification-label-frame">
                        <div className="settings-notification-label">通知設定</div>
                    </div>
                </div>
                <NotificationForVehicles/>
            </div>
        )
    }
}

export default withRouter(SettingsNotificationPage)
