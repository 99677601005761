import './Input.css'

function Input(props){
    return(
        <div className="input-frame">
            <div>{props.title}</div>
            <input id={props.id} type={props.type} autoComplete="off" onChange={props.handleChange} placeholder={props.placeholder}/>
        </div>
    )
}

export default Input