import './BackButton.css';
import BasicIcon from './icons/BasicIcon';

function BackButton(props) {
    
    const buttonName = props.buttonName ? props.buttonName : "戻る"
    return(
        <div className="back" onClick={props.handleBack}>
            <IconSmallArrowLeft/>
            <div className="back-text">{buttonName}</div>
        </div>
    )
}

function IconSmallArrowLeft(props) {
    return (
        <div className="iconsmallarrowleft">
            <BasicIcon className="back-button-icon-frame" src="/arrow_left.svg"/>
        </div>
    )
}

export default BackButton