import InputRange from '../InputRange';

function NotificationVolumeControl(props) {
    return(
        <InputRange id={props.id}
                    title={"通知音ボリューム"}
                    value={props.value}
                    handleChange={props.handleChange}
                    min={"0"}
                    max={"100"}
                    step={"5"}
                    captionHead={props.captionHead}
                    captionBottom={props.captionBottom}/>
    )
}

export default NotificationVolumeControl
