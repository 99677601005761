import React from 'react';
import { connect } from 'react-redux';
import './CallSelectDestinationPage.css';
import RequestClient from '../../common/requestClient';
import { withRouter } from 'react-router';
import PointList from '../../components/DestinationList';
import BackButton from '../../components/BackButton';
import {areaMapVersionIdChanged, checkAreaMapVersionId} from "../../common/AreaMapUtil";



function getPointName(pointList,pointId){
    var pointName = ""
    for(var i=0;i<pointList.length;i++){
        if(pointList[i]["point_id"] == pointId){
            pointName =pointList[i]["name"] 
        }
    }
    return pointName
}

class CallSelectDestinationPage extends React.Component{

    constructor(props){
        super(props)
        this.state = {reserveCount:0}
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    handleBack(){
        this.props.history.push("/")
    }
    componentDidMount(){
        this.updateReservationCount()
        checkAreaMapVersionId(() => {
            areaMapVersionIdChanged();
            this.props.history.push({ pathname: "/error", state: {status: 409} })
        }, (response) => {
            this.props.history.push({ pathname: "/error", state: {status: response.status} })
        })
    }
    updateReservationCount(){
        const url = "/api/reservations"
        RequestClient.get(url)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else if(response.status >= 400){
                this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
        })
        .then(data =>{
            if(data){
                this.setState({reserveCount:data.results.length})
            }
        }
        )
        
    }
    handleNext(event,pointId){
        this.props.onItemClick(pointId)
        this.props.history.push("/call/confirm")
    }

    render(){
        return(
            <div className="call-select-destination-root content-root">
                <div className="call-select-destination-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="call-destination-text-frame">
                        <div className="flex-col">
                            <div className="call-destination-address">
                                <span className="target-point">{getPointName(this.props.pointList, this.props.pointId)} </span>
                                <span>に呼び出す車の行き先を選んでください</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reservation-count-text">予約件数：{this.state.reserveCount}件</div>
                <PointList targetPointId={this.props.pointId} pointList={this.props.pointList} handleNext={this.handleNext}/>
                
            
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
    };
  };
export default connect(mapStateToProps,null)(withRouter(CallSelectDestinationPage));
