import React from 'react';
import RequestClient from "../common/requestClient.js"
import {updateLoginStatus, updateRunViewStatus} from '../actions/userAction';
import {connect} from 'react-redux';
import "./Login.css"
import GreenButton from '../components/buttons/GreenButton.js';
import Input from '../components/Input.js';
import AlertDialog from '../components/AlertDialog.js';
import {getNotificationAgent} from "../workers/NotificationAgent";
import {login} from "../common/AuthUtil";

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.setAlertOpen = this.setAlertOpen.bind(this)
        this.state = {
            workspace_cd: "",
            password: "",
            alertOpen: false,
            alertMsg: "",
        }
        if (this.props.is_LoggedIn) {
            alert("すでにログイン済みです")
            this.props.history.push("/")
        } else {
            getNotificationAgent().quit();
        }
    }

    setAlertOpen(isOpen) {
        this.setState({alertOpen: isOpen});
    }

    setAlertMsg(msg) {
        this.setState({alertMsg: msg});
    }

    login() {
        const url = "/api/auth";
        RequestClient.post(url, this.state)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    this.setAlertMsg(`ステータスコード:${response.status} \n認証に失敗しました`)
                    this.setAlertOpen(true)
                }
            })
            .then(data => {
                if (data) {
                    login(data);
                    this.props.dispatchLogin()
                    this.props.dispatchRunView()
                    this.props.history.push("/")
                }
            }).catch((error) => {
            this.props.history.push({pathname: "/error", state: {message: "ログイン処理に失敗しました。"}})
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validation()) {
            this.login()
        }
    }

    handleChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }

    validation() {
        if (!this.state.workspace_cd) {
            this.setAlertMsg("ログインIDを入力してください")
            this.setAlertOpen(true)
            return false;
        } else if (!this.state.password) {
            this.setAlertMsg("パスワードを入力してください")
            this.setAlertOpen(true)
            return false;
        }
        return true;
    }

    render() {
        const breakedText = this.state.alertMsg.split("\n").map((line, key) => <span key={key}>{line}<br/></span>);
        return (
            <div className="view">
                <img className="logo" src='./eve_logo.svg'/>
                <Input handleChange={this.handleChange} id="workspace_cd" title="ログインID" type="text"
                       placeholder="eveautonomy"/>
                <Input handleChange={this.handleChange} id="password" title="パスワード" type="password"
                       placeholder="********"/>
                <GreenButton onClick={this.handleSubmit} buttonName="ログイン"/>
                <AlertDialog title="ログイン" open={this.state.alertOpen}
                             setOpen={this.setAlertOpen}>{breakedText}</AlertDialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        is_LoggedIn: state.userReducer.is_LoggedIn
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchLogin: () => dispatch(updateLoginStatus()),
    dispatchRunView: () => dispatch(updateRunViewStatus()),
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
