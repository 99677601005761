import PullDownIcon from './icons/PullDownIcon';
import './VehiclePulldown.css';

function VehiclePulldown(props) {
    var vehicleName = ""
    if (props.vehicle && props.vehicle["vehicle_name"]) {
        vehicleName = props.vehicle["vehicle_name"]
    }
    return(
        <div className="vehicle-pulldown" onClick={props.handleOpen}>
            <div className="vehicle-pulldown-text">{vehicleName}</div>
            <div style={{flexGrow:"1"}}></div>
            <PullDownIcon className="vehicle-pulldown-icon"/>

        </div>
    )
}

function VehiclePulldownMb(props) {
    return (
        <select className="vehicle-pulldown-mb" style={{backgroundImage:`url(/pulldown_icon.svg)` , backgroundSize:"24px 24px"}} onClick={props.handleClick} onChange={props.onChange}>
            {props.vehicleList.map((vehicle)=>{
                return <option value={vehicle["vehicle_id"]} key={vehicle["vehicle_id"]} >{vehicle["vehicle_name"]}</option>
            })}
        </select>
    )
}

export {VehiclePulldown, VehiclePulldownMb}