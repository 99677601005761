import './TargetPoint.css';

function TargetPoint(props){
    return(
        <div className={`component-target-point ${props.className}`}>
            <div className="component-target-point-label-frame">
                <div className="component-target-point-label">
                    {props.targetLabel}
                </div>
            </div>
            <div className="component-target-point-address">
                {props.children}
            </div>
        </div>
    )
}

export default TargetPoint