import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setIsLoading } from '../../../actions/systemAction';
import RequestClient from '../../../common/requestClient';
import Loading from '../../../components/Loading';
import {SuccessMsg} from '../../../components/SimpleMsges';

const STATUS_API_CALLING = 0
const STATUS_API_SUCCEEDED = 1

function SucceededComponent(props){
    return(
        <SuccessMsg buttonName="TOPへ" onClick={props.handleTop} message={"同期が完了しました"} />
    )
}

class SettingsSyncVehiclesCompletePage extends React.Component{
    constructor(props){
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleTop = this.handleTop.bind(this)
        this.handleSyncVehicles = this.handleSyncVehicles.bind(this)
    }
    componentDidMount(){
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/settings/sync_vehicles' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        }else{
            this.handleSyncVehicles()
        } 
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }

    handleTop(){
        this.props.history.push("/")
    }
    handleSyncVehicles(){
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/vehicle_relocation"
        const data ={}
        RequestClient.patch(url, data)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else if(response.status >= 400){
                this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
        })
        .then(data => {
            if (data) {
                this.setState({
                    status:STATUS_API_SUCCEEDED
                })
            }
        }).finally(() => { this.props.dispatchSetIsLoading(false) })
    }

    render(){
        var component = ""
        if (this.state.status == STATUS_API_CALLING) {
            component = <Loading>走行環境を同期しています...<br />画面が切り替わるまでお待ちください...</Loading>
        }else if(this.state.status == STATUS_API_SUCCEEDED){
            component = <SucceededComponent handleTop={this.handleTop}/>
        }
        return(
            <div className="settings-sync-vehicles-complete-root content-root">
                {component}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsSyncVehiclesCompletePage));