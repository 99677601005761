import './Button.css';

function BasicButton(props) {
    
    return(
        <div className={`button-frame ${props.className}`}>
            <div className="button-bg-frame" onClick={props.onClick}>
                <div className="button-text">
                    {props.buttonName}
                </div>
            </div>
        </div>
    )
}

export default BasicButton