/**
 * 発進通知を開始／停止する。
 */
import {getNotificationWorkerControl} from "./NotificationWorkerControl";
import {getNotificationConfig} from "../common/NotificationConfig";

/**
 * ポーリング間隔
 * @type {number}
 */
const POLLING_INTERVAL = 20000;

/**
 * ワーカー制御オブジェクト
 * @type {NotificationWorkerControl}
 */
const workerControl = getNotificationWorkerControl();

/**
 * 通知エージェントを取得する。
 * @returns {NotificationAgent}
 */
function getNotificationAgent() {
    return notificationAgent;
}

/**
 * ワーカー設定を返す。
 * @return config {{interval: number, url: string, jwt: string, point_id: number, departure: boolean, arrival: boolean}} 設定パラメータ
 */
function getWorkerConfig() {
    return {
        'interval': POLLING_INTERVAL,
        'url': process.env.REACT_APP_URL + '/api/notification/check',
        'jwt': localStorage.getItem('eva_calls_jwt'),
        'point_id': localStorage.getItem('point_id'),
        'departure': getNotificationConfig().isDepartureOn() ? 'true' : 'false',
        'arrival': getNotificationConfig().isArrivalOn() ? 'true' : 'false',
    }
}

/**
 * Reactアプリケーション上で発進通知機能のワーカー起動／停止操作のインタフェースとなるラッパークラス。
 * ワーカーに関するクラスは、他に notificationWorker （ワーカーそのもの）や NotificationWorkerControl
 * （ワーカーを制御）するクラスがあるが、それらはReactアプリケーションで直接使わず、このクラスを介して使うことにする。
 * このクラスが提供するインタフェースによって、Reactアプリケーションからはワーカー制御をこのクラスに任せることができるようにすることが目的。
 * このクラスのオブジェクトはReactアプリケーションでただ一つとし、必ず getNotificationAgent() によってアクセスする。
 */
class NotificationAgent {

    /**
     * 現在のワーカー設定。
     */
    #currentConfig;

    constructor() {
        console.debug("NotificationAgent initialized.", this);
        this.#currentConfig = null;
    }

    /**
     * 指定されたワーカー設定でワーカー処理を起動する。
     * WorkerControl.start(config) へ処理を渡す前に設定パラメータをチェックしている。
     * WorkerControl.start(config) では例外は発生しないようにする。
     * しかし、もしWorkerControl内で例外が発生した場合でもデバッグログとする。
     * ブラウザでエラーログとして出力しても打つ手がないため。
     * @param config {{}} ワーカー設定
     */
    safe_start(config) {
        if (config.point_id == null) {
            console.debug("Notification agent cannot start worker since point_id is null.");
            return;
        }
        if (config.jwt == null) {
            console.debug("Notification agent cannot start worker since jwt is null.");
            return;
        }
        try {
            workerControl.start(config);
            this.#currentConfig = config;
        } catch (e) {
            console.debug("Notification agent cannot start worker since: " + e.message);
        }
    }

    /**
     * ログインした際に呼び出す。
     */
    start() {
        console.debug("Notification agent start")
        const notificationConfig = getNotificationConfig();

        // 通知設定OFFのとき
        if (notificationConfig.isAllOff()) {
            return;
        }

        // 発進通知設定ONのとき
        // 通知ワーカーが起動していないとき
        if (!workerControl.getStatus().isRunning) {
            const config = getWorkerConfig();
            this.safe_start(config);
        }
    }

    /**
     * 以下の際に呼び出す。
     * * 通知設定On/Offを変更した時
     * * 停車場を変更した時
     */
    applyChange() {
        console.debug("Notification agent apply-change")
        const notificationConfig = getNotificationConfig();

        // 通知設定OFFのとき
        if (notificationConfig.isAllOff()) {
            // ワーカーが起動中なら停止する。
            if (workerControl.getStatus().isRunning) {
                workerControl.stop();
                this.#currentConfig = null;
            }
            return;
        }

        // （発進 and/or 到着）通知設定ONのとき

        // 通知ワーカーが起動していない場合は起動する。
        if (!workerControl.getStatus().isRunning) {
            this.safe_start(getWorkerConfig());
            return;
        }

        // 通知ONかつ通知ワーカーが起動中のとき
        const config = getWorkerConfig();
        // 発進通知ON/OFF、到着通知ON/OFF、停車場のいずれも変更していない場合は何もしない。
        if ((config.point_id === this.#currentConfig.point_id)
            && (config.departure === this.#currentConfig.departure)
            && (config.arrival === this.#currentConfig.arrival)) {
            return;
        }
        // 発進通知ON/OFF、到着通知ON/OFF、停車場のいずれかを変更した場合は再起動する。
        workerControl.stop();
        this.safe_start(config);
    }

    /**
     * 通知機能を停止する。
     * ログアウトの際に呼び出す。
     */
    quit() {
        console.debug("Notification agent quit")
        if (workerControl.getStatus().isRunning) {
            workerControl.stop();
            this.#currentConfig = null;
        }
    }

    /**
     * ワーカーのメッセージイベントハンドラを追加する。
     * @param listener {EventListener} ワーカーのメッセージイベントハンドラ
     */
    addListener(listener) {
        workerControl.replaceListener(listener);
    }

    /**
     * ワーカーのメッセージイベントハンドラを削除する。（実際はデフォルトのハンドラに置き換わる。）
     */
    removeListener() {
        workerControl.removeListener();
    }
}

/**
 * 通知エージェント
 * @type {NotificationAgent}
 */
const notificationAgent = new NotificationAgent();

export {getNotificationAgent}
