import {TAB_INDEX,POINT_ID,IS_LOADING,POINT_LIST} from '../actions/systemAction'

const initialState ={
    tabIndex : 0,
    pointId : localStorage.getItem('point_id') ? localStorage.getItem('point_id') : -1,
    isLoading: false,
    pointList:[]
}
export default function systemReducer(state = initialState,action = {}){
    switch(action.type){
        case TAB_INDEX:
            return Object.assign({},state,{tabIndex : action.tabIndex});
        case POINT_ID:
            return Object.assign({},state,{pointId : action.pointId});
        case IS_LOADING:
            return Object.assign({},state,{isLoading : action.isLoading});
        case POINT_LIST:
            return Object.assign({},state,{pointList : action.pointList});
        default:
            return state;
    }
}