import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { setIsLoading } from '../../../actions/systemAction';
import RequestClient from '../../../common/requestClient';
import Loading from '../../../components/Loading';
import {SuccessMsg,CautionMsg} from '../../../components/SimpleMsges';
import {getAreaMap} from "../../../common/AreaMap";
import {areaMapVersionIdChanged} from "../../../common/AreaMapUtil";

const STATUS_API_CALLING = 0
const STATUS_COMPLETE = 1
const STATUS_ADMIN_PASSWORD_VALID_FAILED = 2

function SettingComplete(props) {
    return(
        <SuccessMsg buttonName="ホーム設定へ" onClick={props.handleSelectVehicle} message="ホーム地点を設定しました" />
    )
}

function InvalidAdminPassword(props) {
    return(
        <CautionMsg buttonName="戻る" onClick={props.handleBack} message="管理者パスワードが正しくありません" />
    )
}
class SettingsHomeCompletePage extends React.Component{
    constructor(props) {
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
        this.handleBack = this.handleBack.bind(this)
    }

    componentDidMount() {
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/settings/vehicles/confirm' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        } else {
            this.setHome()
        }
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }

    handleSelectVehicle() {
        this.props.history.push('/settings/vehicles')
    }
    
    handleBack() {
        this.props.history.push("/settings/vehicles/confirm")
    }

    setHome() {
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/vehicles"
        const data = {
            vehicle_id: this.props.vehicle.vehicle_id,
            home_position_id: this.props.homePositionId,
            admin_password: this.props.adminPassword,
            area_map_version_id: getAreaMap().getVersionId()
        };
        RequestClient.post(url, data)
            .then(response => {
                if (response.status === 200 || response.status === 400) {
                    return response.json()
                }
                if (response.status === 409) {
                    areaMapVersionIdChanged();
                }
                if (response.status >= 400) {
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if (data) {
                    if("code" in data && data["code"] == "ER-0006"){
                        this.setState({status: STATUS_ADMIN_PASSWORD_VALID_FAILED})
                    }else if("result" in data){
                        this.setState({status: STATUS_COMPLETE})
                    }else{
                        this.props.history.push({ pathname: "/error", state: {status: 400} })
                    }
                    
                }
            }).finally(() => { this.props.dispatchSetIsLoading(false) })
    }

    render(){
        var component = ""
        if (this.state.status == STATUS_API_CALLING) {
            component = <Loading>ホーム地点を設定しています...<br />画面が切り替わるまでお待ちください...</Loading>
        }else if(this.state.status == STATUS_COMPLETE) {
            component = <SettingComplete handleSelectVehicle={this.handleSelectVehicle}/>
        }else{
            component =<InvalidAdminPassword handleBack={this.handleBack}/>
        }
        
        return(
            <div className="home-setting-complete-root content-root">
                {component}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsHomeCompletePage));