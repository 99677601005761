import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './AlertDialog.css'

const AlertDialog = (props) => {
  const { title, children, open, setOpen } = props;
  return (
    <Dialog className="alert-dialog-root" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button className="ok-button" variant="contained" onClick={() => setOpen(false)} >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertDialog;