/**
 * 指定された音声をAPIで再生する。
 *
 * APIによる音声再生はブラウザによってブロックされたり、古いブラウザではAPIが異なったりする。
 * 詳細は以下を参照。
 * https://developer.mozilla.org/en-US/docs/web/api/htmlmediaelement/play
 * https://developer.mozilla.org/ja/docs/Web/Media/Autoplay_guide
 * https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/
 * https://developer.chrome.com/blog/autoplay/
 *
 * サーバ側のHTTPヘッダに関する説明もMDNにあった。（GoogleとWebKitにはそのような記述はなかった。）
 * https://developer.mozilla.org/ja/docs/Web/HTTP/Headers/Feature-Policy
 */

const VEHICLE_DEPARTED = "/vehicle_coming.wav";
const VEHICLE_ARRIVED = "/vehicle_arrived.wav";

/**
 * Google's Audio autoplay API usage procedure
 */
let audioCtx = window.AudioContext || window.webkitAudioContext;
if (audioCtx !== undefined) {
    audioCtx = new audioCtx();
}

/**
 * オーディオコンテキストを開始する。
 */
function resumeAudioContext() {
    if (audioCtx === undefined) {
        console.debug("cannot resume AudioContext since it is undefined.");
        return;
    }
    console.debug("AudioContext (before resume): ", audioCtx);
    audioCtx.resume()
        .then(() => {
            console.debug("AudioContext (after resume): ", audioCtx);
        })
        .catch(reason => {
            console.debug("AudioContext-resume seems failed.", audioCtx);
        });
}

/**
 * オーディオプレーヤー
 */
class AudioPlayer {
    #audios;

    constructor() {
        this.#audios = [];
    }

    push(url) {
        this.#audios.push(new Audio(url));
    }

    play(volume) {
        if (this.#audios.length === 0) {
            return;
        }
        for (let i = 0; i < this.#audios.length; i++) {
            let one = this.#audios[i];
            let next = null;
            one.volume = volume;
            if (i < (this.#audios.length - 1)) {
                next = this.#audios[i+1];
                one.onended = (event) => {
                    next.play()
                        .then(() => {
                            console.debug("Audio-play seems complete.");
                        })
                        .catch((reason) => {
                            console.debug("Audio-play seems failed.", reason);
                        })
                }
            }
        }
        const first = this.#audios[0];
        resumeAudioContext();
        first.play()
            .then(() => {
                console.debug("Audio-play seems complete.");
            })
            .catch((reason) => {
                console.debug("Audio-play seems failed.", reason);
            });
        this.#audios.length = 0;
    }
}

export {resumeAudioContext, AudioPlayer, VEHICLE_DEPARTED, VEHICLE_ARRIVED}
