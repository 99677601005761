import RequestClient from '../../common/requestClient';

function getPointId() {
    return localStorage.getItem('point_id');
}

/**
 * 発進通知の詳細情報を取得する。
 * @returns {Promise<Response>}
 */
function getNotificationDetail() {
    const point_id = getPointId();
    const query = '/api/notification/coming/detail?point_id=' + encodeURIComponent(point_id);
    return RequestClient.get(query);
}

export default getNotificationDetail
