export const tabInfos = [
    {"tabname":"Top",　"tabvalue":"TOP","path":"/"},
    {"tabname":'Reservation',　"tabvalue":"予約一覧","path":'/reservations'},
    {"tabname":'Schedule',　"tabvalue":"スケジュール","path":'/schedules'},
    {"tabname":'Setting',　"tabvalue":"設定","path":'/settings'},
]

export function getTabIndex(tabname){
    var index = 0
    for(var i= 0;i<tabInfos.length;i++){
        if (tabInfos[i]["tabname"] == tabname){
            index = i
        }
    }
    return index
}