import LocalStorageContext from "./LocalStorageContext";
import {parseISOString} from "./DateTimeUtil";

const ARRIVED_TIME_TOO_OLD_SEC = 25.0;

/**
 * 通知された到着通知のスケジュールIDと車両情報、到着時刻を管理する。
 * FMSスケジュールIDをキー、到着車両、到着時刻等を含むオブジェクトを値とするハッシュテーブル。
 */
class ArrivedVehicles extends LocalStorageContext {
    constructor() {
        super("arrived_vehicles");
    }

    /**
     * 受け取った車両情報がデータベースに登録されていない場合は追加する。
     * 戻り値がtrueの場合、新規通知があったことを意味する。
     * @param vehicle {{schedule_id: string, vehicle_id: string, vehicle_name: string, arrival_time: string}} 到着した車両データ
     * @returns {boolean} 追加したエントリがあればtrue。
     */
    putNewData(vehicle) {
        const key = vehicle.schedule_id;
        if (key === undefined) {
            return false;
        }
        const db = this.restore();
        if (db[key] === undefined) {
            vehicle['shown'] = false;
            db[key] = vehicle;
            this.save(db);
            return true;
        }
        return false;
    }

    /**
     * 古い通知データをデータベースから削除する。
     * 通知ダイアログを表示した後、もはや通知されることのない古いデータをデータベースから削除しデータベースのサイズを小さくするために使用する。
     * 到着通知は現在日時から20秒以上前に到着した情報は到着通知不要と判断する。
     * 通知ダイアログに表示するまではこのメソッドを呼び出さないことに注意する。呼び出すと到着データを表示できなくなる。
     * 一度表示した車両情報には表示された旨のフラグ（shown）をtrueにセットする。
     */
    clearOldData() {
        // 新データベース
        const newDb = {};
        // 既存データベース
        const currentDb = this.restore();
        const now = new Date().getTime();
        Object.keys(currentDb).forEach((scheduleId) => {
            const vehicle = currentDb[scheduleId];
            const arrived_time = parseISOString(vehicle.arrival_time).getTime();
            if ((now - arrived_time) / 1000 <= ARRIVED_TIME_TOO_OLD_SEC) {
                vehicle.shown = true;
                newDb[scheduleId] = vehicle;
            }
        });
        this.save(newDb);
    }
}

const arrivedVehicles = new ArrivedVehicles();

// 到着通知を使用しない場合でもロードされた時点でストレージに初期化データを保存する。
if (arrivedVehicles.restore() === null) {
    arrivedVehicles.initialize();
}

function getArrivedVehicles() {
    return arrivedVehicles;
}

export {getArrivedVehicles}
