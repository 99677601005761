import React from 'react';
import './DestinationList.css';

function List(props) {
    const {itemName} = props;
    return (
        <div className="destination-list-item">
            <div className="destination-list-item-bg-frame" onClick={props.onClick}>
                <div className="destination-list-item-text">
                    {itemName}
                </div>

            </div>
        </div>
    )
}
function getListItem(targetPointId, pointId, name,handleNext){
    if (pointId == targetPointId){
        return null;
    }
    return( 
        <List itemName={name}  key={pointId} onClick={(event)=>handleNext(event,pointId)}/>
            );
}

function PointList(props){
    const {pointList, targetPointId, handleNext} = props
    var columnCount = targetPointId ? Math.floor((pointList.length - 1)/4):Math.floor((pointList.length)/4)
    if (targetPointId && (pointList.length-1) %4 !=0){
        columnCount = columnCount + 1
    }else if (!targetPointId && (pointList.length) %4 !=0){
        columnCount = columnCount + 1
    }
    columnCount = columnCount <3 ? columnCount : 3
    return(
        <div className={`destination-list-${columnCount} destination-list`}>
            {pointList.map((point)=>getListItem(targetPointId, point["point_id"], point["name"], handleNext))}
        </div>
    )
}

export default PointList