import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {updateLoginStatus, updateRunViewStatus} from './actions/userAction';
import Login from './pages/Login';
import PrivatePage from './pages/PrivatePage';
import Error from './pages/Error';
import { connect } from 'react-redux';

class App extends React.Component{

  constructor(props){
    super(props);
    this.props.dispatchUpdateLoginStatus()
    this.props.dispatchUpdateEnableRunViewStatus()
  }
  render(){
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path='/login' component={Login}/>
            <Route exact path='/error' component={Error}/>
            <Route path='/' component={PrivatePage}/>
          </Switch>
        </Router>
        <footer className="footer">&copy; 2021 eve autonomy, Inc.</footer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    is_LoggedIn: state.userReducer.is_LoggedIn
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchUpdateLoginStatus: () => dispatch(updateLoginStatus()),
  dispatchUpdateEnableRunViewStatus: () => dispatch(updateRunViewStatus())
});
export default connect(mapStateToProps, mapDispatchToProps)(App);