import React from 'react';
import './NotificationForVehicles.css';
import {isNotificationOn, NotificationSwitch} from "./NotificationSwitch";
import NotificationVolumeControl from "./NotificationVolumeControl";
import {
    getNotificationConfig,
    SWITCH_ARRIVAL_KEY_NAME,
    SWITCH_KEY_NAME,
    VOLUME_KEY_NAME
} from "../../common/NotificationConfig";
import {getNotificationAgent} from "../../workers/NotificationAgent";
import {getNotifiedSchedules} from "../../common/NotifiedSchedules";
import NotificationVolumeMute from "./NotificationVolumeMute";
import NotificationVolumeMax from "./NotificationVolumeMax";
import {resumeAudioContext} from "../../common/AudioPlayer";
import {getArrivedVehicles} from "../../common/ArrivedVehicles";
import {getNotificationView} from "../../common/NotificationView";

class NotificationForVehicles extends React.Component {

    constructor(props) {
        super(props)
        const ntfConfig = getNotificationConfig();
        this.state = {
            [SWITCH_KEY_NAME]: ntfConfig.isDepartureOn(),
            [VOLUME_KEY_NAME]: ntfConfig.getVolume(),
            [SWITCH_ARRIVAL_KEY_NAME]: ntfConfig.isArrivalOn(),
        }
        this.handleDepartureNotificationToggle = this.handleDepartureNotificationToggle.bind(this);
        this.handleArrivalNotificationToggle = this.handleArrivalNotificationToggle.bind(this);
        this.handleVolumeChange = this.handleVolumeChange.bind(this);
        this.handleVolumeMute = this.handleVolumeMute.bind(this);
        this.handleVolumeMax = this.handleVolumeMax.bind(this);
        this.setVolume = this.setVolume.bind(this);
    }

    /**
     * ボリュームを設定する
     * @param volume {number} 通知音量（0 <= volume <= 100）
     */
    setVolume(volume) {
        getNotificationConfig().setVolume(volume);
        this.setState((state) => {
            return {
                [VOLUME_KEY_NAME]: volume
            }
        })
    }

    handleVolumeChange(e) {
        this.setVolume(e.target.value);
    }

    handleVolumeMute(e) {
        this.setVolume(0);
    }

    handleVolumeMax(e) {
        this.setVolume(100);
    }

    handleArrivalNotificationToggle(e) {
        const isOn = isNotificationOn(e.target.value);
        if (isOn) {
            getNotificationConfig().setArrivalOn();
            // オーディオコンテキストを再開。
            resumeAudioContext();
        } else {
            getNotificationConfig().setArrivalOff();
            // 通知がOFFになったら通知済みスケジュールテーブルをクリアする。
            getArrivedVehicles().initialize();
            if (!getNotificationConfig().isDepartureOn()) {
                // 発進通知もOFFならば通知アイコンはfalseにセット。
                // 発進通知リストが空の場合は、あえて通知アイコンをfalseにはしないこと（通知されたのにアイコンが表示されないのを防ぐため）。
                // このため、通知設定をOFFにしたのと同時に通知があった場合、通知アイコンが表示されてしまう可能性が残るが、その事象を起こすのはかなり難しいだろう。
                getNotificationView().disableIcon();
            }
        }
        getNotificationAgent().applyChange();
        this.setState((state) => {
            return {
                [SWITCH_ARRIVAL_KEY_NAME]: isOn
            }
        })
    }

    handleDepartureNotificationToggle(e) {
        const isOn = isNotificationOn(e.target.value);
        if (isOn) {
            getNotificationConfig().setDepartureOn();
            // オーディオコンテキストを再開。
            resumeAudioContext();
        } else {
            getNotificationConfig().setDepartureOff();
            // 通知がOFFになったら通知済みスケジュールテーブルをクリアする。
            getNotifiedSchedules().initialize();
            if (!getNotificationConfig().isArrivalOn()) {
                // 到着通知もOFFならば通知アイコンはfalseにセット。
                // 到着通知リストが空の場合は、あえて通知アイコンをfalseにはしないこと（通知されたのにアイコンが表示されないのを防ぐため）。
                // このため、通知設定をOFFにしたのと同時に通知があった場合、通知アイコンが表示されてしまう可能性が残るが、その事象を起こすのはかなり難しいだろう。
                getNotificationView().disableIcon();
            }
        }
        getNotificationAgent().applyChange();
        this.setState((state) => {
            return {
                [SWITCH_KEY_NAME]: isOn
            }
        })
    }

    render() {
        return (
            <section className={"settings-notification-for-vehicles"}>
                <fieldset>
                    <legend>発進通知</legend>
                    <div className={"settings-notification-enabled"}>
                        <NotificationSwitch
                            id={"settings_departure_notification_toggle"}
                            title={"発進通知"}
                            value={this.state[SWITCH_KEY_NAME]}
                            handleChange={this.handleDepartureNotificationToggle}
                            captionHead={<div>OFF</div>}
                            captionBottom={<div>ON</div>}
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>到着通知</legend>
                    <div className={"settings-notification-enabled"}>
                        <NotificationSwitch
                            id={"settings_arrival_notification_toggle"}
                            title={"到着通知"}
                            value={this.state[SWITCH_ARRIVAL_KEY_NAME]}
                            handleChange={this.handleArrivalNotificationToggle}
                            captionHead={<div>OFF</div>}
                            captionBottom={<div>ON</div>}
                        />
                    </div>
                </fieldset>
                <fieldset>
                    <legend>ボリューム</legend>
                    <div className={"settings-notification-volume"}>
                        <NotificationVolumeControl
                            id={"settings_notification_volume"}
                            value={this.state[VOLUME_KEY_NAME]}
                            handleChange={this.handleVolumeChange}
                            captionHead={<NotificationVolumeMute className={"volume-control"}
                                                                 onClick={this.handleVolumeMute}/>}
                            captionBottom={<NotificationVolumeMax className={"volume-control"}
                                                                  onClick={this.handleVolumeMax}/>}
                        />
                    </div>
                </fieldset>
            </section>
        )
    }
}

export default NotificationForVehicles
