import React from 'react';
import './ReturnSelectVehiclePage.css';
import { withRouter } from 'react-router';
import RequestClient from '../../common/requestClient';
import VehiclePulldownList from '../../components/VehicleSelectDialog';
import QuestionMsgDialog from '../../components/QuestionMsgDialog';
import { connect } from 'react-redux';
import GreenButton from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import { VehiclePulldown, VehiclePulldownMb} from '../../components/VehiclePulldown';
import Question from '../../components/Question';
import ConfirmDialog from '../../components/ConfirmDialog';
import AlertDialog from '../../components/AlertDialog';
import {areaMapVersionIdChanged} from "../../common/AreaMapUtil";
import {getAreaMap} from "../../common/AreaMap";

class ReturnSelectVehiclePage extends React.Component{
    constructor(props){
        super(props)
        this.state={
            openQuestion:false,
            openVehicleList:false,
            vehicle: {},
            vehicleList: [],
            confirmOpen:false,
            alertOpen:false,
        }
        this.handleQuestion=this.handleQuestion.bind(this)
        this.handleVehicleListOpen=this.handleVehicleListOpen.bind(this)
        this.handleVehicleListClose=this.handleVehicleListClose.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.setConfirmOpen = this.setConfirmOpen.bind(this)
        this.onConfirm = this.onNext.bind(this)
        this.setAlertOpen = this.setAlertOpen.bind(this)
    }
    
    componentDidMount() {
        this.getVehicles(true)
    }

    getVehicles(isFirst) {
        const pointExist = (pointId)=>{
            for(var i=0;i<this.props.pointList.length;i++){
              if(this.props.pointList[i]["point_id"] == pointId){
                  return true
              }
            }
            return false
          }
        const area_map_version_id = getAreaMap().getVersionId();
        const url = `/api/vehicles?is_home=1&point_id=${encodeURIComponent(this.props.pointId)}&area_map_version_id=${encodeURIComponent(area_map_version_id)}`;
        RequestClient.get(url)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            if (response.status === 409) {
                areaMapVersionIdChanged();
            }
            if (response.status >= 400) {
                this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
        })
            .then(data => {
                if (data) {
                    var vehicles =[]
                    data.results.forEach(vehicle => {
                        if(pointExist(vehicle.home_position_id)){
                            vehicles.push(vehicle)
                        }
                    });
                    if (isFirst) {
                        this.setState({ vehicle: vehicles.length >0 ? vehicles[0]:{}, vehicleList: vehicles })
                    } else {
                        this.setState({ vehicleList: vehicles })
                    }

                }
            }
            )
    }

    handleCancel(){
        this.setState({
            openQuestion:false,
            openVehicleList:false
        })
    }

    handleQuestion(){
        this.setState({
            openQuestion:!this.state.openQuestion
        })
    }
    
    handleBack() {
        this.props.history.push("/")
    }

    handleNext() {
        if ("vehicle_id" in this.state.vehicle) {
            if (this.state.vehicle.home_position_id == this.props.pointId){
                this.setConfirmOpen(true)
            }else{
                this.onNext()
            }
        } else {
            this.setAlertOpen(true)
        }
    }
    setConfirmOpen(isOpen){
        this.setState({confirmOpen:isOpen});
    }
    setAlertOpen(isOpen){
        this.setState({alertOpen:isOpen});
    }
    onNext(){
        this.props.handleSetVehicle(this.state.vehicle)
        this.props.history.push({pathname:"/returnhome/confirm",state:{ref:"/returnhome"}})
    }    

    handleVehicleListOpen(){
        if(!this.state.openVehicleList){
            this.getVehicles(false)
        }
        this.setState({
            openVehicleList:true
        })
    }
    handleVehicleListClose(vehicle){
        this.setState({
            vehicle:vehicle,
            openVehicleList:false
        })
    }
    handleOnChange(e){
        this.state.vehicleList.map((vehicle)=>{
            if(vehicle["vehicle_id"]==e.target.value){
                this.setState({vehicle:vehicle})
            }
        })
    }
    pullDown(){
        return(
            <>
                <VehiclePulldown vehicle={this.state.vehicle} handleOpen={this.handleVehicleListOpen}/>
                {this.state.openVehicleList && (<VehiclePulldownList selectedVehicle={this.state.vehicle} vehicleList={this.state.vehicleList} handleSelected={this.handleVehicleListClose} handleCancel={this.handleCancel}/>)}
            </>
        )
    }
    mbPullDown(){
        return(
            <VehiclePulldownMb vehicle={this.state.vehicle} vehicleList={this.state.vehicleList} handleClick={() =>this.getVehicles(false)} onChange={this.handleOnChange}/>
        )
    }
    
    render(){
        const isMobile = window.innerWidth < 1024
        const pullDownComponent = isMobile ? this.mbPullDown() : this.pullDown()
        return(
            <div className='return-select-vehicle-root content-root'>
                <div className="return-select-vehicle-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="return-select-vehicle-label-frame">
                        <div className="return-select-vehicle-label">ホームに戻す車両を選んでください</div>
                    </div>
                </div>
                {pullDownComponent}
                {this.state.openQuestion && (<QuestionMsgDialog handleCancel={this.handleCancel}>車両の占有LEDは消灯していますか？<br/>車両にホームが設定されていますか？<br/>車両の走行環境変更は行いましたか？</QuestionMsgDialog>)}
                <Question handleOpen={this.handleQuestion}>車が表示されない場合</Question>
                <GreenButton onClick={this.handleNext} buttonName="つぎへ"/>
                <ConfirmDialog title="ホームに戻す" open={this.state.confirmOpen} setOpen={this.setConfirmOpen} onConfirm={this.onConfirm}>
                    使用場所と車両に設定されているホーム地点が同一です。<br/>既に車両がホーム地点にある場合、この操作はエラーとなりますがよろしいですか？
                </ConfirmDialog>
                <AlertDialog title="ホームに戻す" open={this.state.alertOpen} setOpen={this.setAlertOpen}>車両が選択されていません。</AlertDialog>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
      pointId:state.systemReducer.pointId,
      pointList:state.systemReducer.pointList,
    };
  };

export default connect(mapStateToProps,null)(withRouter(ReturnSelectVehiclePage));