/**
 * 日時のユーティリティ
 */

/**
 * ISOフォーマットの日時文字列をパースしてDateオブジェクトを返す。
 * @param s ISOフォーマット文字列
 * @returns {Date}
 */
function parseISOString(s) {
    return new Date(Date.parse(s));
}

/**
 * 発進通知の日時表示用にDateオブジェクトをフォーマットする。
 * @param d {Date}
 * @returns {string} フォーマットした日時分
 */
function formatDateHourMin(d) {
    return `${d.getFullYear()}年${(d.getMonth()+1).toString()}月${d.getDate().toString()}日 ${d.getHours()}時${d.getMinutes()}分`;
}

/**
 * 発進通知の日時表示用にDateオブジェクトをフォーマットする。
 * @param d {Date}
 * @returns {string} フォーマットした日時分
 */
function formatHourMin(d) {
    return `${d.getHours()}時${d.getMinutes()}分`;
}

export {parseISOString, formatDateHourMin, formatHourMin}
