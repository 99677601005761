import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RequestClient from '../../../common/requestClient';
import BackButton from '../../../components/BackButton';
import "./SettingsVehicleListPage.css"
import HomeIcon from '../../../components/icons/HomeIcon';
import BasicIcon from '../../../components/icons/BasicIcon';
import {getAreaMap} from "../../../common/AreaMap";
import {areaMapVersionIdChanged} from "../../../common/AreaMapUtil";


function VehicleListHeader(props) {
    return(
        <div className="settings-vehicle-list-header">
            <div className="settings-vehicle-list-header-frame">
            <div className="settings-vehicle-list-header-no">No.</div>
            <div className="settings-vehicle-list-header-vehicle-name">車両名</div>
            <div className="settings-vehicle-list-header-home">ホーム</div>
            </div>
        </div>
    )
}
function getPointName(pointList, pointId) {
    var pointName = ""
    for (var i = 0; i < pointList.length; i++) {
        if (pointList[i]["point_id"] == pointId) {
            pointName = pointList[i]["name"]
        }
    }
    return pointName
}

function VehicleList(props) {
    return(
        <div className="settings-vehicle-list-data">
            {props.vehicleList.map((row, index)=>{
                return (
                    <Vehicle key={row.vehicle_id} no={index + 1} vehicleName={row["vehicle_name"]} homeName={getPointName(props.pointList, row["home_position_id"])} onClick={()=>props.handleSetting(row)}/>
                )
            })}
        </div>
    )
}
function VehicleListMb(props) {
    return(
    <div className="settings-vehicle-list-data-mb">
            {props.vehicleList.map((row, index)=>{
                return (
                    <VehicleMb key={row.vehicle_id} no={index + 1} vehicleName={row["vehicle_name"]} homeName={getPointName(props.pointList, row["home_position_id"])} onClick={()=>props.handleSetting(row)}/>
                )
            })}
    </div>
    )
}
function Vehicle(props) {
    return(
        <div className="settings-vehicle-list-item">
            <div className="settings-vehicle-list-item-content">
                <div className="settings-vehicle-list-item-no-frame">
                    <div className="settings-vehicle-list-item-no">{props.no}</div>
                </div>
            <div className="settings-vehicle-list-item-vehicle-icon-frame">
            <BasicIcon className="settings-vehicle-list-item-vehicle-icon" src="/settings_vehicle.svg"/>
            </div>
            <div className="settings-vehicle-list-item-vehicle-frame">
                <div className="settings-vehicle-list-item-vehicle">{props.vehicleName}</div>
            </div>
            <HomeIcon className="settings-vehicle-list-item-home-icon-frame"/>
            <div className="settings-vehicle-list-item-home-frame">
                <div className="settings-vehicle-list-item-home">
                    {props.homeName}
                </div>
            </div>
            <div className="settings-vehicle-list-item-link" onClick={props.onClick}>ホーム設定</div>
            </div>
        </div>
    )
    
}
function VehicleMb(props) {
 return(
    <div className="settings-vehicle-list-item">
        <div className="settings-vehicle-list-item-content">
            <div className="settings-vehicle-list-item-no">{props.no}</div>
            <div style={{flexGrow:"0.3"}}></div>
            <div className="settings-vehicle-list-item-vehicle-frame">
                <div className="settings-vehicle-list-item-vehicle">
                    {props.vehicleName}
                    </div>
            </div>
            <HomeIcon className="settings-vehicle-list-item-home-icon-frame"/>
            <div className="settings-vehicle-list-item-home-frame">
                <div className="settings-vehicle-list-item-home">
                    {props.homeName}
                </div>
            </div>
        </div>
        <div style={{flexGrow:"1"}}></div>
        <div className="settings-vehicle-list-item-link" onClick={props.onClick}>ホーム設定</div>
    </div>
 )   
}
class SettingsVehicleListPage extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            vehicleList: []
        }
        this.handleSettingsMenu = this.handleSettingsMenu.bind(this)
        this.handleSetting = this.handleSetting.bind(this)
    }
    componentDidMount() {
        this.props.initialState()
        this.getVehicles()
    }
    handleSetting(vehicle) {
        this.props.handleSetting(vehicle)
    }

    getVehicles() {
        const area_map_version_id = getAreaMap().getVersionId();
        const url = `/api/vehicles?is_home&area_map_version_id=${encodeURIComponent(area_map_version_id)}`;
        RequestClient.get(url)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            if (response.status === 409) {
                areaMapVersionIdChanged();
            }
            if (response.status >= 400) {
                this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
        })
            .then(data => {
                if (data) {
                    this.setState({ vehicleList: data.results })
                }
            }
            )
    }

    handleSettingsMenu() {
        this.props.history.push("/settings")
    }
    mbVehicleTable(){
        return(
        <div className="settings-vehicle-list">
                <VehicleListMb pointList={this.props.pointList} vehicleList={this.state.vehicleList} handleSetting={this.handleSetting}/>
            </div>
            )
    }
    vehicleTable(){
        return(
            <div className="settings-vehicle-list">
                    <VehicleListHeader/>
                    <div className="settings-vehicle-list-line"></div>
                    <VehicleList pointList={this.props.pointList} vehicleList={this.state.vehicleList} handleSetting={this.handleSetting}/>
                </div>
        )
    }
    
    render(){    
        const vehicleTableComponent = window.innerWidth < 1024? this.mbVehicleTable(): this.vehicleTable()
        return (
            <div className="settings-vehicle-list-root content-root">
                <div className="settings-vehicle-list-page">
                    <BackButton handleBack={this.handleSettingsMenu}/>
                    <div className="settings-vehicle-list-label-frame">
                        <div className="settings-vehicle-list-label">ホーム設定</div>
                    </div>
                </div>
                {vehicleTableComponent}
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        pointList: state.systemReducer.pointList,
    };
};

export default connect(mapStateToProps, null)(withRouter(SettingsVehicleListPage))