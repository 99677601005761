import React from 'react';
import './Loading.css';
import VehicleIcon from './icons/VehicleIcon';
function Loading(props){
    const {children} = props
    return(
        <>
            <VehicleIcon className="loading-vehicle-icon"/>
            <div className="loading-text">
                {children ? children :<>車を手配しています...<br />画面が切り替わるまでお待ちください...</>}
            </div>
        </>
    )
}
export default Loading