import createWorker from "./createWorker";
import notificationWorker from "./notificationWorker";

/**
 * 通知ワーカーを操作するオブジェクト
 * @type {NotificationWorkerControl}
 */
let workerControl = undefined;

/**
 * 許容するエラー数
 */
const CONSECUTIVE_ERRORS_NUM_LIMIT = 3;

/**
 * 通知ワーカーコントロールをインスタンス化して返す。
 * シングルトンパターン
 * @returns {NotificationWorkerControl} 通知ワーカーコントロールのインスタンス
 */
export function getNotificationWorkerControl() {
    if (workerControl !== undefined) {
        return workerControl;
    }
    workerControl = new NotificationWorkerControl();
    return workerControl;
}

/**
 * 設定パラメータをチェックする。
 * @param config {{interval: number|null, url: string|null, jwt: string|null, point_id: number|null, departure: boolean, arrival: boolean}} 設定パラメータ
 */
function checkConfig(config) {
    if (config === undefined) {
        throw new Error("Notification configuration object is undefined!");
    }
    if ((config.interval === undefined) || (config.interval === null)) {
        throw new Error("interval required");
    }
    if ((config.url === undefined) || (config.url === null)) {
        throw new Error("url required");
    }
    if ((config.jwt === undefined) || (config.jwt === null)) {
        throw new Error("jwt required");
    }
    if ((config.point_id === undefined) || (config.point_id === null)) {
        throw new Error("point_id required");
    }
    if (config.departure === undefined) {
        throw new Error("departure required");
    }
    if (config.arrival === undefined) {
        throw new Error("arrival required");
    }
}

/**
 * ポーリングが連続してエラーだった場合、無駄なリクエストを抑制するためワーカーを停止する。
 */
class ConsecutiveErrors {
    // エラーリスト
    #errors;

    // 許容するエラー数
    #limit;

    /**
     * @param limit {number} 許容する最大値
     */
    constructor(limit) {
        this.#errors = [];
        this.#limit = limit;
    }

    clear() {
        this.#errors = [];
    }

    incr(e) {
        this.#errors.push(e);
    }

    isOverLimit() {
        return (this.#errors.length > this.#limit);
    }
}

/**
 * エラーを繰り返したらワーカーをストップするリスナー
 * @param e {MessageEvent<any>} ワーカーからのメッセージイベント
 */
function stopListener(e) {
    if (e.data.msg === undefined) {
        return;
    }
    if (e.data.msg === 'schedules') {
        consecutiveErrors.clear();
    }
    if (e.data.msg === 'error') {
        consecutiveErrors.incr(e.data);
        if (consecutiveErrors.isOverLimit()) {
            console.debug("consecutive errors reached the limit, stopping worker.", consecutiveErrors);
            workerControl.stop();
        }
    }
}

/**
 * デフォルトのリスナー
 * Reactコンポーネントのイベントハンドラに置き換えられる。
 * @param e {MessageEvent<any>} ワーカーからのメッセージイベント
 */
function defaultListener(e) {
    console.debug(e.data.msg)
}

/**
 * 通知ワーカーを制御するクラス
 * ワーカーを直接操作するAPIを提供する。
 * どんな操作を行うかの判断はAPIのクライアントが行い、このクラスでは行わない。
 */
class NotificationWorkerControl {
    /**
     * 通知ワーカー
     * @type {Worker}
     */
    #worker = undefined;

    /**
     * ワーカーが実行中のときtrue
     * @type {boolean}
     */
    #isRunning = false;

    /**
     * ワーカーからのメッセージに対応するイベントリスナー
     * @type {EventListener}
     */
    #eventListener = undefined;

    constructor() {
        console.debug("WorkerControl initialized: ", this)
        this.#worker = createWorker(notificationWorker);
        this.#eventListener = defaultListener;
        this.#worker.addEventListener('message', defaultListener)
        this.#worker.addEventListener('message', stopListener);
    }

    /**
     * ワーカーの状態を取得する。
     * @returns {{isRunning: boolean}}
     */
    getStatus() {
        return {
            isRunning: this.#isRunning
        }
    }

    /**
     * 発進通知のポーリングを開始する。
     * @param config {{interval: number|null, url: string|null, jwt: string|null, point_id: number|null, departure: boolean, arrival: boolean}} 設定パラメータ
     */
    start(config) {
        if (this.#isRunning) {
            throw new Error("Worker is already running.")
        }
        checkConfig(config);
        this.#worker.postMessage({
            'action': 'start',
            'interval': config.interval,
            'url': config.url,
            'jwt': config.jwt,
            'point_id': config.point_id,
            'departure': config.departure,
            'arrival': config.arrival
        });
        consecutiveErrors.clear();
        this.#isRunning = true;
    }

    /**
     * 発進通知のポーリングを停止する。
     */
    stop() {
        this.#worker.postMessage({
            action: 'stop'
        });
        this.#isRunning = false;
    }

    /**
     * ワーカーからのメッセージイベントリスナーを置き換える。
     * Reactコンポーネントのstateハンドラに置き換えられる想定。
     *
     * 注意：
     * ワーカーの起動／停止とリスナー紐づけ／紐付け解除は一般に関連しないことに注意する。
     * * ワーカーの起動／停止に関わらずリスナー登録／解除は可能である。
     * * ワーカーの起動中であっても、Reactコンポーネント（インスタンス）の生存期間によっては解除され再登録される。（componentDidMount/componentWillUnmount）
     * すなわち、ワーカーを停止する際にリスナーを解除してはならないし、ワーカーを起動する際にリスナー登録しなければならない訳ではない。
     * removeListener() も参照。
     * @param newListener {EventListener} メッセージイベントハンドラ
     */
    replaceListener(newListener) {
        console.debug("listener replaced with: " + newListener.name)
        const currentListener = this.#eventListener;
        this.#worker.removeEventListener('message', currentListener);
        this.#eventListener = newListener;
        this.#worker.addEventListener('message', newListener);
    }

    /**
     * ワーカーからのメッセージイベントリスナーをデフォルトのリスナーに置き換える。
     * Reactコンポーネントのstateハンドラが削除される時（componentWillUnmountが呼び出された時）に必要。
     * replaceListener() のコメントも参照。
     */
    removeListener() {
        this.replaceListener(defaultListener);
    }
}

/**
 * 連続エラーを管理するオブジェクト
 */
const consecutiveErrors = new ConsecutiveErrors(CONSECUTIVE_ERRORS_NUM_LIMIT);
