import React from 'react';
import './QuestionMsgDialog.css';

function QuestionMsgDialog(props) {
    const {children,handleCancel} = props
    const isMobile = window.innerWidth < 1024
    const iconPrefix = isMobile ? "_mb" : ""
    return(
        <div className="question-dialog-overray" onClick={handleCancel} >
            <div className="question-msg-dialog">
                <div className="question-msg-dialog-bg" style={{backgroundImage: `url(/speech_ballon${iconPrefix}.png)`}} onClick={(e) => e.stopPropagation()}>
                    <div className="question-msg-text">
                        {children}
                    </div>
                </div>
            </div>
        </div>
        )
}

export default QuestionMsgDialog