import React from 'react';
import "./SchedulesPage.css"
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RequestClient from '../../common/requestClient';
import BasicIcon from '../../components/icons/BasicIcon';
import ArrowIconForListItem from '../../components/icons/ArrowIconForListItem';

function ScheduleListHeader(props) {
    return(
        <div className="schedule-list-header">
            <div className="schedule-list-header-frame">
                <div className="schedule-list-header-no-frame">
                    <div className="schedule-list-header-no">No.</div>
                </div>
                <div className="schedule-list-header-vehicle-name-frame">
                    <div className="schedule-list-header-vehicle-name">車両名</div>
                </div>
                <div className="schedule-list-header-call-point-frame">
                    <div className="schedule-list-header-call-point">呼び出す場所</div>
                </div>
                <div className="schedule-list-header-destination-point-frame">
                    <div className="schedule-list-header-destination-point">行き先</div>
                </div>
                <div className="schedule-list-header-status-frame">
                    <div className="schedule-list-header-status">ステータス</div>
                </div>
                <div className="schedule-list-header-register-date-frame">
                    <div className="schedule-list-header-register-date">登録日時</div>
                </div>
            </div>
        </div>
    )
}
function ScheduleList(props){
    return(
        <div className="schedule-list">
            {props.scheduleList.map((row, index) => {
                return (<Schedule  key={row.schedule_id} 
                            no={index +1} vehicleName={row.vehicle_name} 
                            callPointName={getPointName(props.pointList, row.pickup_point_id)}
                            destinationPointName={getPointName(props.pointList, row.dropoff_point_id)}
                            status={row.vehicle_status}
                            registerDate={row.register_date} enableRunView={props.enableRunView}
                            handleRunView={()=>props.handleRunView(row.vehicle_id, row.vehicle_name, row.pickup_point_id)}/>)
            })}
            
            
        </div>
    )
}
function ScheduleListMb(props) {
    return(
        <div className="schedule-list-mb">
            {props.scheduleList.map((row, index) => {
            return(<ScheduleItemMb  key={row.schedule_id} 
                            no={index +1} vehicleName={row.vehicle_name} 
                            callPointName={getPointName(props.pointList, row.pickup_point_id)}
                            destinationPointName={getPointName(props.pointList, row.dropoff_point_id)}
                            status={row.vehicle_status}
                            registerDate={row.register_date} enableRunView={props.enableRunView}
                            handleRunView={()=>props.handleRunView(row.vehicle_id, row.vehicle_name, row.pickup_point_id)}/>)
            })}
        </div>
    )
}

function Schedule(props) {
    const {no, vehicleName, callPointName, destinationPointName, status, registerDate, enableRunView, handleRunView} = props
    const date = new Date(registerDate)
    const yyyymmdd = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    const hhmm = date.getHours() + ":" + toDoubleDigits(date.getMinutes());
    return(
        <div className="schedule-list-item">
            <div className="schedule-list-item-frame">
            <div className="schedule-list-item-no-frame">
                <div className="schedule-list-item-no">
                    {no}
                </div>
            </div>
            <div className="schedule-list-item-vehicle-name-frame">
                <div className="schedule-list-item-vehicle-name">
                    {vehicleName}
                </div>
            </div>
            <div className="schedule-list-item-call-point-frame">
                <div className="schedule-list-item-call-point">
                    {callPointName}
                </div>
            </div>
            <div className="schedule-list-item-destination-point-frame">
                <div className="schedule-list-item-destination-point">
                    {destinationPointName}
                </div>
            </div>
            <div className="schedule-list-item-status-frame">
                {getStatusComponent(status , enableRunView, handleRunView)}
            </div>
            <div className="schedule-list-item-register-date-frame">
                <div className="schedule-list-item-register-date-dt">
                {yyyymmdd}
                </div>
                <div className="schedule-list-item-register-date-time">{hhmm}</div>
            </div>
            </div>

        </div>
    )
}
function ScheduleItemMb(props) {
    const {no, vehicleName, callPointName, destinationPointName, status, registerDate, enableRunView, handleRunView} = props
    const date = new Date(registerDate)
    const yyyymmdd = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    const hhmm = date.getHours() + ":" + toDoubleDigits(date.getMinutes());
    const pointLength = callPointName.length>=destinationPointName.length ? callPointName.length : destinationPointName.length
    const pointComponent =  getMbPointComponent(pointLength > 12 ,callPointName,destinationPointName)
    return(
        <div className="schedule-list-item">
            <div className="schedule-list-item-info-frame" >
                <div className="schedule-list-item-no">
                    {no}
                </div>
                
                <div className="schedule-list-item-vehicle-name-frame">
                    <div className="schedule-list-item-vehicle-name">
                        {vehicleName}
                    </div>
                </div>
                <div style={{flexGrow:"1"}}></div>
                <div className="schedule-list-item-register-date-frame">
                    <BasicIcon className="schedule-list-item-register-date-icon" src="/clock_icon.svg"/>
                    <div className="schedule-list-item-register-date-dt">
                    {yyyymmdd} {hhmm}
                    </div>
                </div>
            </div>
            {pointComponent}    
            {getStatusComponentMb(status, enableRunView, handleRunView)}
        </div>
    )
}
function getMbPointComponent(isLongName, callPointName, destinationPointName){
    if(isLongName){
        return(
            <div className="schedule-list-item-navi-frame-2" >
                    <div className="schedule-list-item-call-point">
                        {callPointName}
                    </div>
                    <div className="schedule-list-item-destination-point-frame-2">
                        <div className="schedule-list-item-arrow-frame">
                            <ArrowIconForListItem  className="schedule-list-item-arrow-icon"/>
                        </div>
                        <div className="schedule-list-item-destination-point">
                        {destinationPointName}
                        </div>
                    </div>
            </div>
        )    
    }
    return(
        <div className="schedule-list-item-navi-frame" >
                <div className="schedule-list-item-call-point">
                    {callPointName}
                </div>
                <div className="schedule-list-item-arrow-frame">
                    <ArrowIconForListItem  className="schedule-list-item-arrow-icon"/>
                </div>
                <div className="schedule-list-item-destination-point">
                    {destinationPointName}
                </div>
            </div>
    )
}
function getPointName(pointList, pointId) {
    var pointName = ""
    for (var i = 0; i < pointList.length; i++) {
      if (pointList[i]["point_id"] == pointId) {
        pointName = pointList[i]["name"]
      }
    }
    return pointName
}
function toDoubleDigits(num) {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
   return num;     
}
function getStatus(status, enableRunView){
    var value=""
    if(status == 1 && enableRunView){
        value = "発進手続きへ"
    }
    else if(status == 1){
        value = "発車待ち"
    }else if(status == 2){
        value = "呼び出し中"
    }else if(status == 3){
        value = "荷役待ち"
    }else{
        value = "エラー"
    }
    return value
}
function getStatusComponent(status,enableRunView,handleClick) {
    if(status == 1 && enableRunView){
        return (
            <div className="schedule-list-vehicle-run-button" onClick={handleClick}>
            <div className="schedule-list-run-button-text schedule-list-item-status">
                {getStatus(status,enableRunView)}
            </div>
        </div>
        )
    }
    return(
        <div className="schedule-list-item-status">
            {getStatus(status,enableRunView)}
        </div>
        )
}
function getStatusComponentMb(status,enableRunView,handleClick) {
    if(status == 1 && enableRunView){
        return (
            <div className="schedule-list-vehicle-run-button" onClick={handleClick}>
            <div className="schedule-list-run-button-text schedule-list-item-status ">
                {getStatus(status,enableRunView)}
            </div>
        </div>
        )
    }
    return(
        <div className="schedule-list-item-status-button-frame">
            <div className="schedule-list-item-status">
                {getStatus(status,enableRunView)}
            </div>
        </div>
        )
}

class SchedulesPage extends React.Component{
    
    constructor(props) {
        super(props)
        this.state = {
          scheduleList: [],
          updateTime: "",
          intervalId: -1
        }
      }

    componentDidMount() {
        this.getSchedules()
        this.updateSchedules()
    }
    componentWillUnmount() {
        this.stopInterval()
      }
    updateSchedules() {
        const intervalId = setInterval(() => this.getSchedules(), 60000)
        this.setState({ intervalId: intervalId })
    }
    
    toDoubleDigits(num) {
        num += "";
        if (num.length === 1) {
          num = "0" + num;
        }
       return num;     
    };

    stopInterval() {
        if (this.state.intervalId > -1) {
          clearInterval(this.state.intervalId)
        }
    }

    getSchedules() {
        const url = "/api/schedules?point_id=" + this.props.pointId
        RequestClient.get(url)
          .then(response =>{
            if (response.status===200){
              return response.json()
            } else if(response.status >= 400){
              this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
          })
          .then(data => {
            if (data) {
              const nowDt = new Date();
              const yyyymmdd = nowDt.getFullYear() + "/" + this.toDoubleDigits(nowDt.getMonth() + 1) + "/" + this.toDoubleDigits(nowDt.getDate());
              const hhmmss =   this.toDoubleDigits(nowDt.getHours()) + ":" + this.toDoubleDigits(nowDt.getMinutes()) + ":" + this.toDoubleDigits(nowDt.getSeconds());
              const time =  yyyymmdd + " " +  hhmmss;
              this.setState({ scheduleList: data.results, updateTime: time })
            } 
          }
          )
      }
    
    mbRender(){
        return(
            <div className="schedule-list-page">
                <ScheduleListMb pointList={this.props.pointList} scheduleList={this.state.scheduleList} enableRunView={this.props.enableRunView} handleRunView={this.props.handleRunView}/>
            </div>
        )
    }
    render(){
        const isMobile = window.innerWidth < 1024
        if(isMobile){
            return this.mbRender()
        }
        return(
            <div className="schedule-list-page">
                <ScheduleListHeader/>
                <div className="schedule-list-line"></div>
                <ScheduleList pointList={this.props.pointList} scheduleList={this.state.scheduleList} enableRunView={this.props.enableRunView} handleRunView={this.props.handleRunView}/>
            </div>
        )
    }
    
    

}


const mapStateToProps = state => {
    return {
      pointId: state.systemReducer.pointId,
      pointList: state.systemReducer.pointList,
      enableRunView:state.userReducer.enableRunView,
    };
  };
  
  export default connect(mapStateToProps, null)(withRouter(SchedulesPage));