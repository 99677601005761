import React from 'react';
import {Route, Switch} from 'react-router-dom'
import {setTabIndex} from '../../actions/systemAction';
import {connect} from 'react-redux';
import {getTabIndex} from '../../conf/urlInfo'
import SettingsVehicleListPage from './home/SettingsVehicleListPage';
import SettingsSelectHome from './home/SettingsSelectHomePage';
import SettingsHomeConfirmPage from './home/SettingsHomeConfirmPage';
import SettingsHomeCompletePage from './home/SettingsHomeCompletePage';
import SettingsMenuPage from './SettingsMenuPage';
import SettingsAdminPasswordPage from './admin_password/SettingsAdminPasswordPage';
import SettingsPasswordPage from './password/SettingsPasswordPage';
import SettingsSyncVehiclesPage from './sync/SettingsSyncVehiclesPage';
import SettingsNotificationPage from './notification/SettingsNotificationPage'

class SettingsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            vehicle: {},
            homePositionId: -1,
            adminPassword: "",
            apiExecFlg: true
        }
        this.handleSetting = this.handleSetting.bind(this)
        this.handleInitialState = this.handleInitialState.bind(this)
        this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
        this.handleSetHomePosition = this.handleSetHomePosition.bind(this)
        this.handleInitialAdminPassword = this.handleInitialAdminPassword.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    componentDidMount() {
        const index = getTabIndex("Setting")
        this.props.dispatchSetTabId(index)
    }

    handleSetApiExecFlg(execFlg) {
        this.setState({apiExecFlg: execFlg})
    }

    handleSetting(vehicle) {
        this.setState({vehicle: vehicle})
        this.props.history.push('/settings/vehicles/home')
    }

    handleSetHomePosition(pointId) {
        this.setState({homePositionId: pointId})
    }

    handleOnChange(e) {
        this.setState({[e.target.id]: e.target.value});
    }


    handleInitialState() {
        this.setState(
            {
                vehicle: {},
                homePositionId: -1,
                adminPassword: ""
            }
        )
    }

    handleInitialAdminPassword() {
        this.setState(
            {adminPassword: ""}
        )
    }

    render() {
        return (
            <Switch>
                <Route exact path='/settings'>
                    <SettingsMenuPage/>
                </Route>
                <Route exact path='/settings/vehicles'>
                    <SettingsVehicleListPage initialState={this.handleInitialState} handleSetting={this.handleSetting}/>
                </Route>
                <Route exact path='/settings/vehicles/home'>
                    <SettingsSelectHome vehicle={this.state.vehicle} homePositionId={this.state.homePositionId}
                                        handleSetHomePosition={this.handleSetHomePosition}/>
                </Route>
                <Route exact path='/settings/vehicles/confirm'>
                    <SettingsHomeConfirmPage vehicle={this.state.vehicle} homePositionId={this.state.homePositionId}
                                             handleSetApiExecFlg={this.handleSetApiExecFlg}
                                             onChange={this.handleOnChange} adminPassword={this.state.adminPassword}
                                             initialAdminPassword={this.handleInitialAdminPassword}/>
                </Route>
                <Route exact path='/settings/vehicles/complete'>
                    <SettingsHomeCompletePage vehicle={this.state.vehicle} homePositionId={this.state.homePositionId}
                                              handleSetApiExecFlg={this.handleSetApiExecFlg}
                                              apiExecFlg={this.state.apiExecFlg}
                                              adminPassword={this.state.adminPassword}/>
                </Route>
                <Route path='/settings/admin_password'>
                    <SettingsAdminPasswordPage handleSetApiExecFlg={this.handleSetApiExecFlg}
                                               apiExecFlg={this.state.apiExecFlg}/>
                </Route>
                <Route path='/settings/password'>
                    <SettingsPasswordPage handleSetApiExecFlg={this.handleSetApiExecFlg}
                                          apiExecFlg={this.state.apiExecFlg}/>
                </Route>
                <Route path='/settings/sync_vehicles'>
                    <SettingsSyncVehiclesPage handleSetApiExecFlg={this.handleSetApiExecFlg}
                                              apiExecFlg={this.state.apiExecFlg}/>
                </Route>
                <Route path='/settings/notification'>
                    <SettingsNotificationPage/>
                </Route>
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch => ({
    dispatchSetTabId: tabIndex => dispatch(setTabIndex(tabIndex)),
})
export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)