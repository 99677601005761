import BasicIcon from '../icons/BasicIcon';

function NotificationVolumeMute(props) {
    return(
        <BasicIcon
            className={props.className}
            src="/sound_mute.svg"
            alt={"mute"}
            onClick={props.onClick}
        />
    )
}

export default NotificationVolumeMute
