import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setIsLoading } from '../../../actions/systemAction';
import RequestClient from '../../../common/requestClient';
import Loading from '../../../components/Loading';
import {SuccessMsg} from '../../../components/SimpleMsges';

const STATUS_API_CALLING = 0
const STATUS_COMPLETE = 1

function SettingComplete(props) {
    return(
        <SuccessMsg buttonName="TOPへ" onClick={props.handleTop} message="管理者パスワードを変更しました" />
    )
}

class SettingsAdminPasswordCompletePage extends React.Component{
    
    constructor(props) {
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleTop = this.handleTop.bind(this)
    }

    handleTop() {
        this.props.history.push('/')
    }

    componentDidMount() {
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/settings/admin_password' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        } else {
            this.changeAdminPassword()
        }
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }

    changeAdminPassword() {
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/admin_password"
        const data = {
            admin_password: this.props.admin_password,
            new_admin_password: this.props.new_admin_password

        };
        RequestClient.patch(url, data)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status >= 400){
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if (data) {
                    this.setState({status: STATUS_COMPLETE})
                }
            }).finally(() => { this.props.dispatchSetIsLoading(false) })
    }

    render(){
        var component =""
        if (this.state.status == STATUS_API_CALLING) {
            component = <Loading>管理者パスワードを変更しています...<br />画面が切り替わるまでお待ちください...</Loading>
        }else{
            component = <SettingComplete handleTop={this.handleTop}/>
        }
        return (
            <div className="adminpassword-setting-complete-root content-root">
                {component}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsAdminPasswordCompletePage));