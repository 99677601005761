import React from 'react';
import './Error.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {updateLoginStatus} from '../actions/userAction';
import Button from '../components/buttons/WhiteButton';
import CautionIcon from '../components/icons/CautionIcon';

function Error(props){
    return(
        <div className="error-root content-root">
            <div className="error-frame">
                <CautionIcon className="error-icon"/>
                <div className="error-text">{props.children}</div>
            </div>
            <Button buttonName={props.buttonName} onClick={props.handleTop}/>
        </div>
    )
}
class ErrorPage extends React.Component{
    constructor(props){
        super(props)
        this.toLogin = this.toLogin.bind(this)
        this.toTop = this.toTop.bind(this)
    }
    toLogin(){
        this.props.history.push("/login")
    }
    toTop(){
        this.props.history.push("/")
    }
    render(){
        if(this.props.location.state === undefined){
            return (
                <Error buttonName="TOPへ" handleTop={this.toTop} >
                    不正な遷移です。
                </Error>
                ) 
        }else if (this.props.location.state.status === 401){
            localStorage.removeItem("eva_calls_jwt")
            this.props.dispatchUpdateLoginStatus()
            return(
            <Error buttonName="ログインへ" handleTop={this.toLogin} >
                認証に失敗しました。<br/>再ログインしてください。
            </Error>
            )
        } else if (this.props.location.state.status === 409) {
            this.props.dispatchUpdateLoginStatus()
            return(
                <Error buttonName="ログインへ" handleTop={this.toLogin} >
                    地図が更新されました。<br/>再ログインしてください。
                </Error>
            )
        }else if (this.props.location.state.status >= 400 && this.props.location.state.status < 500) {
            return(
                <Error buttonName="TOPへ" handleTop={this.toTop} >
                    リクエストに失敗しました。
                </Error>
                ) 
        }else if(this.props.location.state.status>=500){
            return (
                <Error buttonName="TOPへ" handleTop={this.toTop} >
                    接続に失敗しました。
                </Error>
                ) 
        }else{
            return (
                <Error buttonName="TOPへ" handleTop={this.toTop} >
                    {this.props.location.state.message}
                </Error>
                )
        }
        
    }
}

const mapStateToProps = state => {
    return { 
    };
  };

const mapDispatchToProps = dispatch=>({
    dispatchUpdateLoginStatus: () => dispatch(updateLoginStatus())
  })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ErrorPage));