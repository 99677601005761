import React from 'react';
import './PointSelectDialog.css'

function CarStopList(props) {
    const {children} = props
    return(
        <div className="car-stop-list" onClick={(event)=>props.handleListItemClick(event,props.pointId)}>
            <div className="car-stop-item-frame">
                <div className="address-frame">
                    <div className="address valign-text-middle">{children}</div>
                </div>
            </div>
        </div>
    )
    
}

class PointSelectDialog extends React.Component{

    constructor(props){
        super(props)
        this.handleCancel.bind(this)
        this.handleListItemClick.bind(this)
    }

    handleCancel = ()=>{
        this.props.onClose()
    }

    handleListItemClick = (event, point_id) => {
        this.props.onClose(point_id)
    }

    render(){
        if(!this.props.open){
            return ""
        }else{
            const listItems = this.props.pointList.map((point) =>{
                if(point["point_id"]==this.props.point_id){
                    return ""
                }else{
                    return <CarStopList pointId ={point["point_id"]} key={point["point_id"]} handleListItemClick={this.handleListItemClick}>{point["name"]}</CarStopList>
                }
        })
            return(
                <div className="overray" onClick={this.handleCancel} >
                    <div className="dialog" onClick={(e) => e.stopPropagation()}>
                        <div className="dialog-text valign-text-middle">使用場所を選んでください</div>
                        {listItems}
                    </div>
                </div>
            )
        }
        
    }
}

export default PointSelectDialog