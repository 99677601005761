import React from 'react';
import { connect } from 'react-redux';
import './ReservationCancelCompletePage.css';
import { withRouter } from 'react-router';
import { setIsLoading } from '../../actions/systemAction';
import RequestClient from '../../common/requestClient';
import Loading from '../../components/Loading';
import GreenButton from '../../components/buttons/GreenButton';
import {SuccessMsg,CautionMsg} from '../../components/SimpleMsges';

const STATUS_API_CALLING = 0
const STATUS_CANCEL = 1
const STATUS_REGIST_SCHEDULE = 2
const STATUS_CANCELED = 3

function CancelComplete(props) {
    return(
        <div className='reservation-cancel-complete-cancel-complete-root content-root'>
            <SuccessMsg buttonName="TOPへ" onClick={props.handleTop} message="予約をキャンセルしました">
            </SuccessMsg>
        </div>
    )
}

function RegistedSchedule(props){
    return(
        <div className='reservation-cancel-complete-registed-schedule-root content-root'>
            <CautionMsg buttonName="TOPへ" onClick={props.handleTop} message={`キャンセルできません\n既にスケジュール登録されています`}>
                <GreenButton buttonName="スケジュール確認" onClick={props.handleSchedules}/>
            </CautionMsg>
        </div>
    )
}

class ReservationCancelCompletePage extends React.Component{
    
    constructor(props) {
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleSchedules = this.handleSchedules.bind(this)
        this.handleTop = this.handleTop.bind(this)
    }
    
    componentDidMount() {
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/reservations/cancel/confirm' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        } else {
            this.cancelReservation()
        }
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }

    handleSchedules() {
        this.props.history.push('/schedules')
    }
    handleTop(){
        this.props.history.push('/')
    }

    cancelReservation() {
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/reservations"
        const data = {
            reservation_id: this.props.reservation.reservation_id,
            is_active:0

        };
        RequestClient.patch(url, data)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status >= 400){
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if (data) {
                    if (data.result.update_status == 1) {
                        this.setState({ status: STATUS_CANCEL })
                    } else if (data.result.update_status == 2) {
                        this.setState({ status: STATUS_CANCELED })
                    } else if (data.result.update_status == 3) {
                        this.setState({ status: STATUS_REGIST_SCHEDULE })
                    }
                }
            }).finally(() => { this.props.dispatchSetIsLoading(false) })
    }

    render(){
        var component = ""
        if (this.state.status == STATUS_API_CALLING) {
            component= <div className='content-root'><Loading>予約をキャンセルしています...<br />画面が切り替わるまでお待ちください...</Loading></div>
        }else if (this.state.status == STATUS_REGIST_SCHEDULE) {
            component= <RegistedSchedule handleTop={this.handleTop} handleSchedules={this.handleSchedules}/>
        }else{
            component= <CancelComplete handleTop={this.handleTop}/>
        }
        return(
            <>
            {component}
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationCancelCompletePage));