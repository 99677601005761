import React from 'react';
import { connect } from 'react-redux';
import './ReservationCancelConfirmPage.css';
import { withRouter } from 'react-router';
import GreenButton from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import VehicleIcon from '../../components/icons/VehicleIcon';
import EnableArrowIcon from '../../components/icons/EnableArrowIcon';
import DisableArrowIcon from '../../components/icons/DisableArrowIcon';
import EnableTargetPoint from '../../components/TargetPoints/EnableTargetPoint';
import DisableTargetPoint from '../../components/TargetPoints/DisableTargetPoint';

class ReservationCancelConfirmPage extends React.Component{

    constructor(props){
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    
    componentDidMount(){
        if(!Object.keys(this.props.reservation).length || this.props.pointId < 0){
            this.props.history.push("/reservations")
        }
    }

    handleBack(){
        this.props.history.push("/reservations")
    }

    handleNext(){
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/reservations/cancel/complete", state: { ref: "/reservations/cancel/confirm" } })
    }
    
    getPointName(pointList, pointId){
        var pointName = ""
        for(var i=0;i<pointList.length;i++){
            if(pointList[i]["point_id"] == pointId){
                pointName =pointList[i]["name"] 
            }
        }
        return pointName
    }


    render(){
        const title = window.innerWidth < 1024 ? <>以下の予約を<br/>キャンセルしますか？</> : <>以下の予約をキャンセルしますか？</>
        return(
            <div className='reservation-cancel-confirm-root content-root'>
                <div className="reservation-cancel-confirm-title">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="reservation-cancel-confirm-text-frame">
                        <div className="reservation-cancel-confirm-text">{title}</div>
                    </div>
                </div>
                <div className="reservation-cancel-confirm-content-frame">
                    <VehicleIcon className="reservation-cancel-vehicle-icon"/>
                    <EnableArrowIcon className="reservation-cancel-arrow-icon"/>
                    <EnableTargetPoint prefixName="call" targetLabel="呼び出す場所" >{this.getPointName(this.props.pointList,this.props.pointId)}</EnableTargetPoint>
                    <DisableArrowIcon className="reservation-cancel-arrow-icon"/>
                    <DisableTargetPoint prefixName="reservation-cancel-destination" targetLabel="行き先">{this.getPointName(this.props.pointList,this.props.reservation.dropoff_point_id)}</DisableTargetPoint>
                </div>
                <GreenButton buttonName="予約をキャンセルする" onClick={this.handleNext}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
    };
  };
export default connect(mapStateToProps,null)(withRouter(ReservationCancelConfirmPage));
