import React from 'react';
import { connect } from 'react-redux';
import './SendCompletePage.css';
import { withRouter } from 'react-router';
import { setIsLoading } from '../../actions/systemAction';
import RequestClient from '../../common/requestClient';
import Loading from '../../components/Loading';
import Button from '../../components/buttons/GreenButton';
import {SuccessMsg} from '../../components/SimpleMsges';
import {getAreaMap} from "../../common/AreaMap";
import {areaMapVersionIdChanged} from "../../common/AreaMapUtil";

const STATUS_API_CALLING = 0
const STATUS_COMPLETE = 1

function ScheduleRegistComplete(props) {
    const {enableRunView, handleRunView} = props 
    const runViewComponent = enableRunView ? <RunViewComponent handleRunView={handleRunView}/>:""
    return(
        <SuccessMsg buttonName="TOPへ" onClick={props.handleTop} message={"手配しました\n車の発進ボタンを押してください"}>
            {runViewComponent}
        </SuccessMsg>
    )
}

function RunViewComponent(props) {
    return (
        <>
            <div className="run-view-text">もしくは</div>
            <Button buttonName="このまま発進手続きへ" onClick={props.handleRunView}/>
        </>
    )
}

class SendCompletePage extends React.Component{

    constructor(props) {
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleTop = this.handleTop.bind(this)
        this.handleRunView = this.handleRunView.bind(this)
    }
    
    componentDidMount() {
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/send/confirm' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        } else {
            this.registSchedule()
        }
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }
    handleTop() {
        this.props.history.push('/')
    }
    handleRunView(){
        this.props.history.push({ pathname: "/send/vehicle_run/confirm", state: { ref: "/send/complete" } })
    }

    registSchedule() {
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/schedules"
        const data = {
            dropoff_point_id:this.props.destinationPointId,
            vehicle_id: this.props.vehicle["vehicle_id"],
            is_home:0,
            area_map_version_id: getAreaMap().getVersionId()
        };
        RequestClient.post(url, data)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                }
                if (response.status === 409) {
                    areaMapVersionIdChanged();
                }
                if (response.status >= 400) {
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if(data){
                    if (data["result"]["result_id"]) {
                        this.setState({ status: STATUS_COMPLETE })
                    }else{
                        this.props.history.push({ pathname: "/error", state: { message: "車両の手配ができませんでした。" } })
                    }
                }
            }).finally(() => { this.props.dispatchSetIsLoading(false) })
    }
    
    render(){
        var component = ""
        if (this.state.status == STATUS_API_CALLING) {
            component= <Loading/>
        } else if (this.state.status == STATUS_COMPLETE) {
            component =<ScheduleRegistComplete enableRunView={this.props.enableRunView} handleRunView={this.handleRunView} handleTop={this.handleTop}/>
        }
        return(
            <div className="send-complete-root content-root">
            {component}    
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        enableRunView:state.userReducer.enableRunView,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SendCompletePage));