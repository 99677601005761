import React from 'react';
import './TopPage.css'
import BasicIcon from '../components/icons/BasicIcon';

function Task(props){
    const {isMobile, icon} = props
    const goTo =()=>{
        props.history.push(props.link)
      }
    if(isMobile){
        return(
            <div className="task" onClick={goTo}>
                <div className="task-label-frame">
                    <BasicIcon className="icon-1" src={icon}/>
                    <div className="task-label">{props.children}</div>
                </div>
            </div>
        )
    }
    return(
        <div className="task" onClick={goTo}>
            <BasicIcon className="icon-1" src={icon}/>
            <div className="task-label">{props.children}</div>
        </div>
    )
}

class TopPage extends React.Component{
    componentDidMount() {
        window.history.pushState(null,null,window.location.href);
        window.addEventListener('popstate', (e) => {
            if(window.location.pathname === '/'){
                this.props.history.push('/');
            }
        });
    }
    render(){
        const isMobile = window.innerWidth < 1024
        return(
            <div className="top-root content-root">
                <div className="flex-row">
                    <Task isMobile={isMobile} link="/call" icon={`./call_icon.svg`}  {...this.props}>車を呼ぶ</Task>
                    <Task isMobile={isMobile} link="/send" icon={`./send_icon.svg`}  {...this.props}>車を送る</Task>
                    <Task isMobile={isMobile} link="/returnhome" icon={`./rethome_icon.svg`}  {...this.props}>ホームに戻す</Task>
                </div>
            </div>
        )
    }
}
export default TopPage