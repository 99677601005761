/**
 * ログイン／ログアウトの関数
 */
import {getNotificationAgent} from "../workers/NotificationAgent";
import {getNotifiedSchedules} from "./NotifiedSchedules";
import {getArrivedVehicles} from "./ArrivedVehicles";
import {getNotificationView} from "./NotificationView";
import {getAreaMap} from "./AreaMap";

/**
 * ローカルストレージのエリアマップバージョンIDが変わっていたら更新する。
 * エリアマップバージョンIDが未設定の場合は設定する。
 * @param data ログインAPIのレスポンスデータ
 * @returns {boolean} エリアマップバージョンIDが更新されていた場合は true を、それ以外の場合は false を返す。
 */
const updateMapVersion = (data) => {
    const area_map = getAreaMap();
    const current_version = area_map.getVersionId();
    if (current_version === null) {
        area_map.setVersionId(data["area_map_version_id"]);
        return false;
    }
    if (current_version !== data["area_map_version_id"]) {
        area_map.setVersionId(data["area_map_version_id"]);
        return true;
    }
    return false;
}

/**
 * ログイン処理
 * @param data ログインAPIのレスポンスデータ
 */
const login = (data) => {
    localStorage.setItem("eva_calls_jwt", data["token"]);
    localStorage.setItem("enable_run_view", data["is_allowed_start_vehicle"]);
    if (updateMapVersion(data)) {
        localStorage.setItem("point_id", "-1");
    }
    getNotifiedSchedules().initialize();
    getArrivedVehicles().initialize();
    getNotificationView().initialize();
}

/**
 * ログアウト処理
 */
const logout = () => {
    localStorage.removeItem("eva_calls_jwt")
    localStorage.removeItem("enable_run_view")
    getNotificationAgent().quit();
}

export {login, logout}
