import './SimpleMsges.css';
import SuccessIcon from './icons/SuccessIcon';
import Button from './buttons/WhiteButton';
import CautionIcon from './icons/CautionIcon';

function SuccessMsg(props){
    return(
        <>
            <div className="simple-msges-frame">
                <SuccessIcon className=""/>
                <div className="complete-text">{props.message}</div>
                <>{props.children}</>
            </div>
            <div className="simple-msges-button-frame">
                <Button buttonName={props.buttonName} onClick={props.onClick}/>
            </div>
        </>
    )
}

function CautionMsg(props){
    return(
        <>
            <div className="simple-msges-frame">
                <CautionIcon className=""/>
                <div className="complete-text">{props.message}</div>
                <>{props.children}</>
            </div>
            <div className="simple-msges-button-frame">
                <Button buttonName={props.buttonName} onClick={props.onClick}/>
            </div>
        </>
    )
}

export {SuccessMsg, CautionMsg}