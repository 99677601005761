import React from 'react';
import {Route, Switch} from 'react-router-dom'
import ReturnSelectVehiclePage from './ReturnSelectVehiclePage';
import ReturnConfirmPage from './ReturnConfirmPage';
import ReturnCompletePage from './ReturnCompletePage'
import VehicleRunConfirmPage from '../vehicle_run/VehicleRunConfirmPage';
import VehicleRunCompletePage from '../vehicle_run/VehicleRunCompletePage';
import { withRouter } from 'react-router';

class ReturnPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            vehicle:{},
            apiExecFlg:true
        }
        this.handleSetVehicle = this.handleSetVehicle.bind(this)
        this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
        this.handleRunViewComplete = this.handleRunViewComplete.bind(this)
        this.handleRunViewBack = this.handleRunViewBack.bind(this)
    }
    

    handleSetVehicle(vehicle){
        this.setState({vehicle:vehicle})
    }

    handleSetApiExecFlg(execFlg){
        this.setState({apiExecFlg:execFlg})
    }
    handleRunViewComplete(){
        this.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/returnhome/vehicle_run/complete", state: { ref: "/vehicle_run/confirm" } })
    }
    handleRunViewBack(){
        this.props.history.push('/')
    }

    render(){
        return (<Switch>
            <Route exact path='/returnhome'>
                <ReturnSelectVehiclePage handleSetVehicle={this.handleSetVehicle}/>
            </Route>
            <Route exact path='/returnhome/confirm'>
                <ReturnConfirmPage vehicle={this.state.vehicle} handleSetApiExecFlg={this.handleSetApiExecFlg} />
            </Route>
            <Route exact path='/returnhome/complete'>
                <ReturnCompletePage vehicle={this.state.vehicle} apiExecFlg={this.state.apiExecFlg} handleSetApiExecFlg={this.handleSetApiExecFlg} />
            </Route>
            <Route exact path='/returnhome/vehicle_run/confirm'>
                <VehicleRunConfirmPage vehicle = {this.state.vehicle} destinationPointId={this.state.vehicle.home_position_id} handleNext={this.handleRunViewComplete}　handleBack={this.handleRunViewBack} backButtonName="TOPへ"/>
            </Route>
            <Route exact path='/returnhome/vehicle_run/complete'>
                    <VehicleRunCompletePage vehicle = {this.state.vehicle} handleSetApiExecFlg={this.handleSetApiExecFlg} apiExecFlg={this.state.apiExecFlg}/>
            </Route>
        </Switch>)
    }
}

export default withRouter(ReturnPage);