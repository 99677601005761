import React from 'react';
import { withRouter } from 'react-router';
import BackButton from '../../../components/BackButton';
import Button from '../../../components/buttons/GreenButton';
import InputPassword from '../../../components/InputPassword';
import './SettingsAdminPasswordInputPage.css';

class SettingsAdminPasswordInputPage extends React.Component{
    
    constructor(props){
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.state = {
            new_adminpassword_preview:false,
            adminpassword_preview:false
        }
        this.handleAdminPasswordPreview = this.handleAdminPasswordPreview.bind(this)
        this.handleNewAdminPasswordPreview = this.handleNewAdminPasswordPreview.bind(this)
    }

    componentDidMount() {
        this.props.clear()
    }

    handleBack() {
        this.props.history.push({ pathname: "/settings", state: { ref: "/settings/admin_password" } })
    }

    handleNewAdminPasswordPreview(preview){
        this.setState(
             {
                 new_adminpassword_preview:preview
            }
        )
    }
    handleAdminPasswordPreview(preview){
        this.setState(
            {
                adminpassword_preview:preview
            }
        )
    }
    
    render(){
        return(
            <div className="settings-adminpassword-input-root content-root">
                <div className="settings-adminpassword-input-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="settings-adminpassword-input-label-frame">
                        <div className="settings-adminpassword-input-label">管理者パスワード変更</div>
                    </div>
                </div>
                <InputPassword handleChange={this.props.handleOnChange} preview={this.state.adminpassword_preview} id="admin_password" type="password" title="現在の管理者パスワード" placeholder="************" handlePreview={this.handleAdminPasswordPreview}/>
                <InputPassword handleChange={this.props.handleOnChange} preview={this.state.new_adminpassword_preview}  id="new_admin_password" type="password" title="新しい管理者パスワード" placeholder="************" handlePreview={this.handleNewAdminPasswordPreview}/>
                <Button buttonName="変更" onClick={this.props.handleChange}/>
            </div>
        )
    }
}
export default withRouter(SettingsAdminPasswordInputPage)