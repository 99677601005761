import React from 'react';
import { connect } from 'react-redux';
import './SendConfirmPage.css';
import { withRouter } from 'react-router';
import Button from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import EnableArrowIcon from '../../components/icons/EnableArrowIcon';
import EnableTargetPoint from '../../components/TargetPoints/EnableTargetPoint';

class SendConfirmPage extends React.Component{

    constructor(props) {
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    
    componentDidMount(){
        if(!Object.keys(this.props.vehicle).length){
            this.props.history.push("/send")
        }else if(this.props.destinationPointId < 0){
            this.props.history.push("/send/destination")
        }
        
    }

    handleBack() {
        this.props.history.push({ pathname: "/send/destination", state: { ref: "/send/confirm" } })
    }
    handleNext() {
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/send/complete", state: { ref: "/send/confirm" } })
    }
    getPointName(pointList, pointId){
        var pointName = ""
        for(var i=0;i<pointList.length;i++){
            if(pointList[i]["point_id"] == pointId){
                pointName =pointList[i]["name"] 
            }
        }
        return pointName
    }

    render(){
        return(
            <div className="send-confirm-root content-root">
                <div className="send-confirm-title">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="send-confirm-text-frame">
                        <div className="send-confirm-text">
                        <span className="send-confirm-text-vehicle">
                            {this.props.vehicle["vehicle_name"]}
                        </span>
                        <span>をこの経路で送りますか？</span>
                        </div>
                    </div>
                </div>
                <div className="send-confirm-content-frame">
                    <EnableTargetPoint targetLabel="現在地">{this.getPointName(this.props.pointList,this.props.pointId)}</EnableTargetPoint>
                    <EnableArrowIcon className="send-arrow-icon"/>
                    <EnableTargetPoint targetLabel="行き先">{this.getPointName(this.props.pointList,this.props.destinationPointId)}</EnableTargetPoint>
                </div>
                <Button buttonName="車両を送る" onClick={this.handleNext}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pointList: state.systemReducer.pointList,
        pointId:state.systemReducer.pointId,
    };
};

export default connect(mapStateToProps, null)(withRouter(SendConfirmPage));