import React from 'react';
import { withRouter } from 'react-router';
import './VehicleRunConfirmPage.css'
import { connect } from 'react-redux';
import GreenButton from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import VehicleIcon from '../../components/icons/VehicleIcon';
import CautionIconColorRed from '../../components/icons/CautionIconColorRed';
import EnableTargetPoint from '../../components/TargetPoints/EnableTargetPoint';
import EnableArrowIcon from '../../components/icons/EnableArrowIcon';

function CautionMsg(props){
    return(
        <div className="vehicle-run-caution-msg-frame">
            <CautionIconColorRed className="vehicle-run-caution-icon"/>
            <div className="vehicle-run-caution-msg">車が動きます。<br/>周囲の安全を確認してください。</div>
        </div>
    )
}

function SlideButton(props){
    
    const backgroundSize = props.isMobile? "35px 35px":"48px 48px" 

    var posX = 0
    var isMove = false
    var isMax = false
    function handleMouseDown(e) {
        posX = e.clientX
        isMove = true
    }
    function handleTouchStart(e) {
        posX = e.changedTouches[0].clientX
        isMove = true
    }
    function handleMouseUp(e) {
        if(isMax){
            isMax = false
            props.handleNext()
        }
        
        isMove = false
        e.target.style.left =  0 + "px"
    }
    function handleMouseMove(e) {
        if(!isMove){
            return
        }
        const maxWidth = e.target.parentElement.offsetWidth - e.target.offsetWidth;
        var newPosX =  (e.clientX - posX)
        if (newPosX >= maxWidth){
            newPosX = maxWidth
            isMax = true;
        }else if (newPosX <0){
            newPosX = 0
        }
        e.target.style.left = newPosX + "px"
    }
    function handleTouchMove(e) {
        if(!isMove){
            return
        }
        const maxWidth = e.target.parentElement.offsetWidth - e.target.offsetWidth;
        var newPosX =  (e.changedTouches[0].clientX - posX)
        if (newPosX >= maxWidth){
            newPosX = maxWidth
            isMax = true;
        }else if (newPosX <0){
            newPosX = 0
        }
        e.target.style.left = newPosX + "px"
    }
    function handleMouseOut(e){
        isMove = false
        e.target.style.left = 0 + "px"
    }
    function touchButton() {
        return <div className="slide-button" style={{backgroundImage: `url(/run_button_arrow_icon.svg)`,backgroundSize:backgroundSize}} onTouchStart={(e)=>handleTouchStart(e)} 
        onTouchMove={(e)=>handleTouchMove(e)} onTouchEnd={(e)=>handleMouseUp(e)} onMouseOut={(e)=>{handleMouseOut(e)}}>
        </div>
    }
    function mouseButton() {
        return <div className="slide-button" style={{backgroundImage: `url(/run_button_arrow_icon.svg)`, backgroundSize:backgroundSize}} onMouseDown={(e)=>handleMouseDown(e)} 
        onMouseMove={(e)=>handleMouseMove(e)} onMouseUp={(e)=>handleMouseUp(e)} onMouseOut={(e)=>{handleMouseOut(e)}}>
        </div>
    }
    const button = 'ontouchstart' in window ? touchButton() :mouseButton()
    return(
        <div className="slider-outside-frame" >
            <div className="slider-inside-frame">
                {button}
                <div className="slide-button-text">スライドして発進する</div>
            </div>
        </div>
    )
}


class VehicleRunConfirmPage extends React.Component{
    constructor(props){
        super(props)
    }
    componentDidMount() {
        if (typeof this.props.location.state === 'undefined'
         || (this.props.location.state.ref !== '/send/complete' && this.props.location.state.ref !== '/returnhome/complete' && this.props.location.state.ref !== '/schedules')
         || this.props.destinationPointId < 0 ||typeof this.props.vehicle.vehicle_id === 'undefined') {
           this.props.history.replace('/');
        } 
    }
    getPointName(pointList, pointId){
        var pointName = ""
        for(var i=0;i<pointList.length;i++){
            if(pointList[i]["point_id"] == pointId){
                pointName =pointList[i]["name"] 
            }
        }
        return pointName
    }
    render(){
        const isMobile = window.innerWidth < 1024
        const isTablet = window.innerWidth < 1400
        const runButton = isTablet? <SlideButton isMobile={isMobile} handleNext={this.props.handleNext}/> :<GreenButton onClick={this.props.handleNext} buttonName="発進する"/>
        return (
            <div className='vehicle-run-confirm-root content-root'>
                <div className="vehicle-run-page">
                    <BackButton handleBack={this.props.handleBack} buttonName={this.props.backButtonName}/>
                    <div className="vehicle-run-page-label-frame">
                        <div className="vehicle-run-page-label">
                            <span className="vehicle-run-page-label-vehicle">{this.props.vehicle.vehicle_name}</span>
                            <span>を本当に発進しますか？</span>
                        </div>
                    </div>
                </div>
                <div className="vehicle-run-confirm-content-frame">
                    <VehicleIcon className="vehicle-run-vehicle-icon"/>
                    <EnableArrowIcon className="vehicle-run-arrow-icon"/>
                    <EnableTargetPoint targetLabel="行き先">{this.getPointName(this.props.pointList,this.props.destinationPointId)}</EnableTargetPoint>
                </div>
                <CautionMsg/>
                {runButton}
            </div>
        )     
    }
}
const mapStateToProps = state => {
    return {
        pointList: state.systemReducer.pointList,
        pointId:state.systemReducer.pointId,
    };
};

export default connect(mapStateToProps, null)(withRouter(VehicleRunConfirmPage));