/**
 * PersistentContext abstract class
 */
class PersistentContext {
    #name;
    #data;

    constructor(name) {
        this.#name = name
        this.#data = {}
    }

    getName() {
        return this.#name
    }

    initialize() {
        this.#data = {}
    }

    restore() {
        return this.#data
    }

    save(data) {
        this.#data = data
    }
}

export default PersistentContext
