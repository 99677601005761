import LocalStorageContext from "./LocalStorageContext";

// area_map_version_idの値を設定するキー
const VERSION_ID_KEY_NAME = "version_id";

// area_map_version_idが設定されていない時の初期値
const VERSION_ID_INIT_VALUE = null;

class AreaMap extends LocalStorageContext {
    constructor() {
        super("area_map");
    }

    initialize() {
        const data = {
            [VERSION_ID_KEY_NAME]: VERSION_ID_INIT_VALUE,
        }
        this.save(data);
    }

    getVersionId() {
        return this.getByKey(VERSION_ID_KEY_NAME);
    }

    setVersionId(versionId) {
        this.update(VERSION_ID_KEY_NAME, versionId);
    }
}

const area_map = new AreaMap();

// ロードされた時点でストレージに初期化データを保存する。
if (area_map.restore() === null) {
    area_map.initialize();
}

function getAreaMap() {
    return area_map;
}

export {getAreaMap}
