import React from 'react';
import './SendSelectVehiclePage.css';
import { withRouter } from 'react-router';
import RequestClient from '../../common/requestClient';
import VehiclePulldownList from '../../components/VehicleSelectDialog';
import QuestionMsgDialog from '../../components/QuestionMsgDialog';
import Button from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import { VehiclePulldown, VehiclePulldownMb} from '../../components/VehiclePulldown';
import Question from '../../components/Question';
import AlertDialog from '../../components/AlertDialog';
import {getAreaMap} from "../../common/AreaMap";
import {areaMapVersionIdChanged} from "../../common/AreaMapUtil";

class SendSelectVehiclePage extends React.Component{
    
    constructor(props){
        super(props)
        this.state={
            openQuestion:false,
            openVehicleList:false,
            vehicle: {},
            vehicleList: [],
            alertOpen:false,
        }
        this.handleQuestion=this.handleQuestion.bind(this)
        this.handleVehicleListOpen=this.handleVehicleListOpen.bind(this)
        this.handleVehicleListClose=this.handleVehicleListClose.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.setAlertOpen = this.setAlertOpen.bind(this)
    }
    componentDidMount() {
        this.getVehicles(true)
    }
    
    handleCancel(){
        this.setState({
            openQuestion:false,
            openVehicleList:false
        })
    }
    getVehicles(isFirst) {
        const area_map_version_id = getAreaMap().getVersionId();
        const url = `/api/vehicles?is_home=0&area_map_version_id=${encodeURIComponent(area_map_version_id)}`
        RequestClient.get(url)
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
            if (response.status === 409) {
                areaMapVersionIdChanged();
            }
            if (response.status >= 400){
                this.props.history.push({ pathname: "/error", state: {status: response.status} })
            } 
        })
        .then(data => {
            if (data) {
                if (isFirst) {
                    this.setState({ vehicle: data.results.length >0 ? data.results[0]:{}, vehicleList: data.results })
                } else {
                    this.setState({ vehicleList: data.results })
                }

            }
        })
    }

    handleQuestion(){
        this.setState({
            openQuestion:!this.state.openQuestion
        })
    }
    
    handleBack() {
        this.props.history.push("/")
    }

    handleNext() {
        if ("vehicle_id" in this.state.vehicle) {
            this.props.handleSetVehicle(this.state.vehicle)
            this.props.history.push({pathname:"/send/destination",state:{ref:"/send"}})
        } else {
            this.setAlertOpen(true)
        }
    }

    setAlertOpen(isOpen){
        this.setState({alertOpen:isOpen});
    }

    handleVehicleListOpen(){
        if(!this.state.openVehicleList){
            this.getVehicles(false)
        }
        this.setState({
            openVehicleList:true
        })
    }
    handleVehicleListClose(vehicle){
        this.setState({
            vehicle:vehicle,
            openVehicleList:false
        })
    }
    handleOnChange(e){
        this.state.vehicleList.map((vehicle)=>{
            if(vehicle["vehicle_id"]==e.target.value){
                this.setState({vehicle:vehicle})
            }
        })
    }
    mbPullDown(){
        return(
            <VehiclePulldownMb vehicle={this.state.vehicle} vehicleList={this.state.vehicleList} handleClick={() =>this.getVehicles(false)} onChange={this.handleOnChange}/>
        )
    }
    pullDown(){
        return(
            <>
            <VehiclePulldown vehicle={this.state.vehicle} handleOpen={this.handleVehicleListOpen}/>
            {this.state.openVehicleList && (<VehiclePulldownList selectedVehicle={this.state.vehicle} vehicleList={this.state.vehicleList} handleSelected={this.handleVehicleListClose} handleCancel={this.handleCancel}/>)}
            </>
        )
    }
    render(){
        const isMobile = window.innerWidth < 1024
        const pullDownComponent = isMobile ? this.mbPullDown():this.pullDown()
        return(
            <div className="send-select-vehicle-root content-root">
                <div className="send-select-vehicle-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="send-select-vehicle-label-frame">
                        <div className="send-select-vehicle-label">配送する車両を選んでください</div>
                    </div>
                </div>
                {pullDownComponent}
                {this.state.openQuestion && (<QuestionMsgDialog handleCancel={this.handleCancel}>車両の占有LEDは点灯していますか？<br/>車両の走行環境変更は行いましたか？</QuestionMsgDialog>)}
                <Question handleOpen={this.handleQuestion}>車が表示されない場合</Question>
                <Button onClick={this.handleNext} buttonName="つぎへ"/>
                <AlertDialog title="車を送る" open={this.state.alertOpen} setOpen={this.setAlertOpen}>車両が選択されていません。</AlertDialog>
            </div>
        )
    }
}

export default withRouter(SendSelectVehiclePage);