import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import './SettingsHomeConfirmPage.css';
import Button from '../../../components/buttons/GreenButton';
import BackButton from '../../../components/BackButton';
import HomeIcon from '../../../components/icons/HomeIcon';
import Input from '../../../components/Input';
import AlertDialog from '../../../components/AlertDialog';

function SelectedHome(props) {
    return(
        <div className="settings-home-confirm-home-frame">
                <div className="settings-home-confirm-home">
                    <HomeIcon className="settings-home-confirm-home-icon"/>
                    <div className="settings-home-confirm-home-label">
                        {props.homeName}
                    </div>
                </div>
            </div>
    )
}
class SettingsHomeConfirmPage extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            alertOpen:false,
            alertMsg:"",
        }
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.setAlertOpen = this.setAlertOpen.bind(this)
        this.setAlertMsg = this.setAlertMsg.bind(this)
    }

    getPointName(pointList, pointId) {
        var pointName = ""
        for (var i = 0; i < pointList.length; i++) {
            if (pointList[i]["point_id"] == pointId) {
                pointName = pointList[i]["name"]
            }
        }
        return pointName
    }

    componentDidMount(){
        if(!Object.keys(this.props.vehicle).length){
            this.props.history.push("/settings/vehicles")
        }else if(this.props.homePositionId < 0){
            this.props.history.push("/settings/vehicles/home")
        }
        this.props.initialAdminPassword()
    }

    handleBack() {
        this.props.history.push({ pathname: "/settings/vehicles/home", state: { ref: "/settings/vehicles/confirm" } })
    }

    handleNext() {
        if(!this.props.adminPassword){
            this.setAlertMsg("管理者パスワードが入力されていません")
            this.setAlertOpen(true)
            return
        }else if(this.props.adminPassword.match(/[^A-Za-z0-9]+/)){
            this.setAlertMsg("管理者パスワードに不正な文字列が含まれています")
            this.setAlertOpen(true)
            return
        }
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/settings/vehicles/complete", state: { ref: "/settings/vehicles/confirm" } })
    }
    setAlertOpen(isOpen){
        this.setState({alertOpen:isOpen});
    }
    setAlertMsg(msg){
        this.setState({alertMsg:msg});
    }
    render(){
        return(
            <div className="settings-home-confirm-root content-root">
                <div className="settings-home-confirm-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="settings-home-confirm-label-frame">
                        <div className="settings-home-confirm-label" onClick={this.handleClick}>
                            <span className="settings-home-confirm-target-vehicle">{this.props.vehicle["vehicle_name"]}</span>
                            <span>のホームを設定します</span>
                        </div>
                    </div>
                </div>
                <SelectedHome homeName={this.getPointName(this.props.pointList, this.props.homePositionId)}/>
                <Input title="管理者パスワード" id="adminPassword" type="password" placeholder="************" handleChange={this.props.onChange}/>
                <Button buttonName="ホームを設定する" onClick={this.handleNext} />
                <AlertDialog title="ホーム設定" open={this.state.alertOpen} setOpen={this.setAlertOpen}>{this.state.alertMsg}</AlertDialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pointList: state.systemReducer.pointList
    };
};

export default connect(mapStateToProps, null)(withRouter(SettingsHomeConfirmPage));