import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RequestClient from '../../common/requestClient';
import './ReservationListPage.css'
import ArrowIconForListItem from '../../components/icons/ArrowIconForListItem';

function ReservationListHeader(props) {
    return(
        <div className="reservation-list-header">
            <div className="reservation-list-header-frame">
                <div className="reservation-list-header-no">No.</div>
                <div className="reservation-list-header-call-point">呼び出す場所</div>
                <div className="reservation-list-header-destination-point">行き先</div>
            </div>
        </div>
    )
}
function ReservationList(props) {
    
    return(
        <div className="reservation-list">
            {props.reservationList.map((row, index) => {
                const active = row["pickup_point_id"] == props.startPoint
                return <ReservationListItem 
                            key={row.reservation_id} index={index} 
                            callPoint={getPointName(props.pointList, row["pickup_point_id"])}
                            destinationPoint={getPointName(props.pointList, row["dropoff_point_id"])}
                            handleCancel={(event) => props.handleCancel(event, row)}
                            cancelEnabled={active}
                            />
            })}
        </div>

    )
    
}
function ReservationListMb(props) {
    return(
        <div className="reservation-list-mb">
            {props.reservationList.map((row, index) => {
                const active = row["pickup_point_id"] == props.startPoint
                if(active){
                    return <ReservationItemCancelEnabled
                    key={row.reservation_id} index={index} 
                    callPoint={getPointName(props.pointList, row["pickup_point_id"])}
                    destinationPoint={getPointName(props.pointList, row["dropoff_point_id"])}
                    handleCancel={(event) => props.handleCancel(event, row)}
                    cancelEnabled={active}
                    />
                }else{
                    return <ReservationItemCancelDisabled 
                            key={row.reservation_id} index={index} 
                            callPoint={getPointName(props.pointList, row["pickup_point_id"])}
                            destinationPoint={getPointName(props.pointList, row["dropoff_point_id"])}
                            handleCancel={(event) => props.handleCancel(event, row)}
                            cancelEnabled={active}
                            />
                }
            })}
        </div>
    )

}
function ReservationItemCancelEnabled(props) {
    const pointLength = props.callPoint.length>=props.destinationPoint.length ? props.callPoint.length : props.destinationPoint.length
    if(pointLength > 10){
        return(
        <div className="reservation-list-item-mb mb-cancel-enabled">
            <div className="reservation-list-item-frame-mb">
                <div className="reservation-list-item-no">{props.index + 1}</div>
                <div className="reservation-list-point-frame-mb-2">
                    <div className="reservation-list-item-call-point">{props.callPoint}</div>
                    <div className="reservation-list-destination-point-frame-mb-2">
                        <div className="reservation-list-item-arrow-frame">
                            <ArrowIconForListItem className="reservation-list-item-arrow"/>
                        </div>
                        <div className="reservation-list-item-destination-point">{props.destinationPoint}</div>
                    </div>
                </div>
            </div>
            <div className="reservation-cancel-frame-mb" onClick={props.handleCancel}>
                <div className="reservation-cancel-text-mb">キャンセル</div>
            </div>
        </div>
        )
    }
    return(
        <div className="reservation-list-item-mb mb-cancel-enabled">
            <div className="reservation-list-item-frame-mb">
                <div className="reservation-list-item-no">{props.index + 1}</div>
                <div className="reservation-list-point-frame-mb">
                    <div className="reservation-list-item-call-point">{props.callPoint}</div>
                    <div className="reservation-list-item-arrow-frame">
                    <ArrowIconForListItem className="reservation-list-item-arrow"/>
                    </div>
                    <div className="reservation-list-item-destination-point">{props.destinationPoint}</div>
                </div>
            </div>
            <div className="reservation-cancel-frame-mb" onClick={props.handleCancel}>
                <div className="reservation-cancel-text-mb">キャンセル</div>
            </div>
        </div>
    )
}
function ReservationItemCancelDisabled(props) {
    const pointLength = props.callPoint.length>=props.destinationPoint.length ? props.callPoint.length : props.destinationPoint.length
    if(pointLength > 10){
        console.debug(pointLength)
        return(<div className="reservation-list-item-mb">
            <div className="reservation-list-item-frame-mb">
                <div className="reservation-list-item-no">{props.index + 1}</div>
                <div className="reservation-list-point-frame-mb-2">
                    <div className="reservation-list-item-call-point">{props.callPoint}</div>
                    <div className="reservation-list-destination-point-frame-mb-2">
                        <div className="reservation-list-item-arrow-frame">
                        <ArrowIconForListItem className="reservation-list-item-arrow"/>
                        </div>
                        <div className="reservation-list-item-destination-point">{props.destinationPoint}</div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    
    return(
        <div className="reservation-list-item-mb">
            <div className="reservation-list-item-frame-mb">
            <div className="reservation-list-item-no">{props.index + 1}</div>
            <div className="reservation-list-point-frame-mb">
                <div className="reservation-list-item-call-point">{props.callPoint}</div>
                <div className="reservation-list-item-arrow-frame">
                <ArrowIconForListItem className="reservation-list-item-arrow"/>
                </div>

                <div className="reservation-list-item-destination-point">{props.destinationPoint}</div>
            </div>
                
            </div>
        </div>
    )
}
function ReservationListItem(props) {
    const cancelButton = props.cancelEnabled ?  
        <div className="reservation-list-item-cancel-link-enable" onClick={props.handleCancel}>キャンセル</div> : <div className="reservation-list-item-cancel-link-disable" >キャンセル</div> 

    return(
        <div className="reservation-list-item">
            <div className="reservation-list-item-frame">
                <div className="reservation-list-item-no-frame">
                    <div className="reservation-list-item-no">{props.index + 1}</div>
                </div>
                <div className="reservation-list-item-call-point-frame">
                    <div className="reservation-list-item-call-point">{props.callPoint}</div>
                </div>
                <div className="reservation-list-item-arrow-frame">
                <ArrowIconForListItem className="reservation-list-item-arrow"/>
                </div>
                <div className="reservation-list-item-destination-point-frame">
                    <div className="reservation-list-item-destination-point">{props.destinationPoint}</div>
                </div>
                <div className="reservation-list-item-cancel-link-frame">
                    {cancelButton}
                </div>
            </div>
        </div>
    )
}

function getPointName(pointList, pointId) {
    var pointName = ""
    for (var i = 0; i < pointList.length; i++) {
        if (pointList[i]["point_id"] == pointId) {
            pointName = pointList[i]["name"]
        }
    }
    return pointName
}

class ReservationListPage extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            reservationList: []
        }
        this.handleCancel = this.handleCancel.bind(this)
    }
    componentDidMount() {
        this.getReservations()
    }
    handleCancel(event, reservation) {
        this.props.handleCancel(reservation)
    }
    getReservations() {
        const url = "/api/reservations"
        RequestClient.get(url)
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if(response.status >= 400){
                    this.props.history.push({ pathname: "/error", state: {status: response.status} })
                } 
            })
            .then(data => {
                if (data) {
                    this.setState({ reservationList: data.results })
                }
            }
            )
    }
    mbRender(){
        return(
            <div className="reservation-list-page">
                <ReservationListMb startPoint={this.props.pointId} pointList={this.props.pointList} reservationList={this.state.reservationList} handleCancel={this.handleCancel}/>
            </div>
        )
    }
    render(){
        const isMobile = window.innerWidth < 1024
        if(isMobile){
            return this.mbRender()
        }
        return(
            <div className="reservation-list-page">
                <ReservationListHeader/>
                <div className="reservation-list-line"></div>
                <ReservationList startPoint={this.props.pointId} pointList={this.props.pointList} reservationList={this.state.reservationList} handleCancel={this.handleCancel}/>
            
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        pointId: state.systemReducer.pointId,
        pointList: state.systemReducer.pointList,
    };
};

export default connect(mapStateToProps, null)(withRouter(ReservationListPage))