import React from 'react';
import './NotificationDetailDialog.css';
import getNotificationDetail from "./getNotificationDetail";
import {getNotifiedSchedules} from "../../common/NotifiedSchedules";
import {formatHourMin, parseISOString} from "../../common/DateTimeUtil";
import LoadingIcon from "../icons/LoadingIcon";
import {getArrivedVehicles} from "../../common/ArrivedVehicles";
import {getNotificationConfig} from "../../common/NotificationConfig";
import ArrivalIcon from "../icons/ArrivalIcon";
import DepartureIcon from "../icons/DepartureIcon";

function createDialogTitle() {
    const ntfConfig = getNotificationConfig();
    if (ntfConfig.isDepartureOn() && !ntfConfig.isArrivalOn()) {
        return "発進車両";
    }
    if (!ntfConfig.isDepartureOn() && ntfConfig.isArrivalOn()) {
        return "到着車両";
    }
    return "到着／発進車両";
}

/**
 * ソートのロジック
 * @param a {{time: string}}
 * @param b {{time: string}}
 * @returns {number}
 */
function byTime(a, b) {
    return (a.time > b.time) ? 1 : ((a.time === b.time) ? 0 : -1);
}

/**
 * 通知ダイアログに表示するデータの配列に到着通知の車両データを追加する。
 * @param list {[{type:string, scheduleId:string, vehicle_id:string, point_id:number, point_name:string, time:string}]} 通知ダイアログに表示するデータの配列
 * @returns {[{type:string, scheduleId:string, vehicle_id:string, point_id:number, point_name:string, time:string}]}
 */
function prependArrivedVehicles(list) {
    const vehicles = []
    for (const [scheduleId, vehicle] of Object.entries(getArrivedVehicles().restore())) {
        if (vehicle.shown) {
            continue;
        }
        vehicles.push(
            {
                'type': 'arrival',
                'scheduleId': scheduleId,
                'vehicle_id': vehicle.vehicle_id,
                'vehicle_name': vehicle.vehicle_name,
                'point_id': null,
                'point_name': null,
                'time': vehicle.arrival_time,
            }
        );
    }
    vehicles.sort(byTime);
    // 古い到着履歴を削除
    getArrivedVehicles().clearOldData();
    return vehicles.concat(list);
}

function DepartureSchedule(props) {
    return (
        <div className="schedule">
            <p className={"leading"}>
                <span className={"schedule-content vehicle"}>{props.vehicle_name}</span><br/>
                <span className={"schedule-content place"}>{props.point_name}</span>
            </p>
            <p className={"time"}>
                <span className={"schedule-content"}>{formatHourMin(parseISOString(props.time))}</span>
            </p>
        </div>
    )
}

function ArrivalSchedule(props) {
    return (
        <div className="schedule">
            <p className={"leading"}>
                <span className={"schedule-content vehicle"}>{props.vehicle_name}</span>
            </p>
            <p className={"time"}>
                <span className={"schedule-content"}>{formatHourMin(parseISOString(props.time))}</span>
            </p>
        </div>
    )
}

function NoScheduleContent(props) {
    const msgDeparture = "発進した車両は既に到着したかスケジュールがキャンセルされました。";
    const msgArrival = "到着した車両はありません。";
    const ntfConfig = getNotificationConfig();
    if (ntfConfig.isDepartureOn() && ntfConfig.isArrivalOn()) {
        return (
            <p>
                {msgArrival}<br/>
                {msgDeparture}<br/>
            </p>
        )
    }
    if (ntfConfig.isDepartureOn()) {
        return (
            <p>
                {msgDeparture}<br/>
            </p>
        )
    }
    if (ntfConfig.isArrivalOn()) {
        return (
            <p>
                {msgArrival}<br/>
            </p>
        )
    }
    return (
        <></>
    )
}

function NotificationListItem(props) {
    let item, name, icon;
    if (props.type === 'departure') {
        item = DepartureSchedule(props);
        name = "departure";
        icon = <DepartureIcon className={name}/>
    } else {
        item = ArrivalSchedule(props);
        name = "arrival";
        icon = <ArrivalIcon className={name}/>
    }
    return (
        <li key={props.scheduleId} className={name}>
            <div className="item-frame">
                <div className="schedule-frame">
                    {icon}
                    {item}
                </div>
            </div>
        </li>
    )
}

/**
 * 発進通知ダイアログ
 * このコンポーネントの表示要否は呼び出し元で管理すること。
 */
class NotificationDetailDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            isLoading: true,
            isError: false,
            errorMessage: ""
        }
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.updateSchedules();
    }

    handleClose() {
        this.props.onClose()
    }

    updateSchedules() {
        const ntfConfig = getNotificationConfig();
        if (!ntfConfig.isDepartureOn()) {
            // 発進通知OFFの場合
            this.setState({
                schedules: prependArrivedVehicles([]),
                isLoading: false,
                isError: false,
                errorMessage: ""
            })
            return;
        }

        // 発進通知ONの場合
        getNotificationDetail()
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`${response.status}: ${response.statusText}`);
                }
            })
            .then((data) => {
                if (data["schedules"]) {
                    return data["schedules"];
                }
                throw new Error("response ok yet no schedules included.");
            })
            .then((schedules) => {
                // 取得したスケジュールに現在の通知ハッシュテーブルの値（あれば）をマージ
                const found = getNotifiedSchedules().getByKeys(schedules);
                for (const [scheduleId, startTime] of Object.entries(found)) {
                    schedules[scheduleId]['start_time'] = startTime;
                }
                return schedules;
            })
            .then((schedules) => {
                /**
                 * このスケジュールを通知済みデータベース（NotifiedSchedules）に登録し、それ以外の（おそらく古い）通知を削除する。
                 * FIXME:
                 * 上記で通知詳細を取得してからここでデータベースを更新するまでの間に新しい通知が来て
                 * 通知済みデータベースに登録された場合、一度通知音が鳴るが、以下のコードでそのスケジュールが削除されるため
                 * 次の通知チェックで同じスケジュールがあった場合、新規通知があったと解釈され再度通知音が鳴ることになる。
                 * ダイアログを開くタイミングによってはこのような現象が発生する。
                 * これを防ぐには、以下の処理を行わないことであるが、この処理を行わないと、通知済みデータベースが肥大化する。
                 * 肥大化した通知済みデータベース（ローカルストレージに保存）をクリアするには、２つの方法がある。
                 *  * 一度ログアウトする。
                 *  * 停車場所を変更する。
                 * 新規通知音が煩わしい場合は、この処理をスキップするなどの判断をする。
                 */
                const keyValues = {};
                Object.keys(schedules).forEach((scheduleId) => {
                    keyValues[scheduleId] = schedules[scheduleId]["start_time"];
                });
                getNotifiedSchedules().clearExceptForKeys(keyValues);
                return schedules;
            })
            .then((schedules) => {
                // オブジェクトを配列に変換する。
                const list = [];
                for (const [scheduleId, detail] of Object.entries(schedules)) {
                    list.push(
                        {
                            'type': 'departure',
                            'scheduleId': scheduleId,
                            'vehicle_id': detail.vehicle_id,
                            'vehicle_name': detail.vehicle_name,
                            'point_id': detail.point_id,
                            'point_name': detail.point_name,
                            'time': detail.start_time,
                        }
                    );
                }
                return list;
            })
            .then((list) => {
                // 発進時刻でソート
                return list.sort(byTime);
            })
            .then((list) => {
                if (!ntfConfig.isArrivalOn()) {
                    return list;
                }
                return prependArrivedVehicles(list);
            })
            .then((list) => {
                // このスケジュールでダイアログの内容を更新する。
                this.setState({
                    schedules: list,
                    isLoading: false,
                    isError: false,
                    errorMessage: ""
                })
                return list;
            })
            .catch((reason) => {
                console.debug("failed to fetch notification details: ", reason);
                this.setState({
                    schedules: [],
                    isLoading: false,
                    isError: true,
                    errorMessage: <p>通知データを取得できませんでした。</p>
                })
            });
    }

    renderSchedules() {
        const schedules = this.state.schedules;
        if (this.state.isLoading) {
            return (
                <LoadingIcon className={"notification-detail-loading"}/>
            )
        }
        if (this.state.isError) {
            return (<></>);
        }
        if (schedules.length === 0) {
            return (
                <NoScheduleContent/>
            )
        }
        const listItems = schedules.map((item) => {
            return <NotificationListItem
                key={item.scheduleId}
                type={item.type}
                scheduleId={item.scheduleId}
                vehicle_name={item.vehicle_name}
                point_name={item.point_name}
                time={item.time}
            />
        });
        return (
            <ul>{listItems}</ul>
        );
    }

    render() {
        const schedules = this.renderSchedules();
        const errorMessage = this.state.errorMessage;
        const dialogTitle = createDialogTitle();
        return (
            <div className={"overlay"} onClick={this.handleClose}>
                <section className="notification-detail-dialog" onClick={(e) => e.stopPropagation()}>
                    <legend className="dialog-title valign-text-middle">{dialogTitle}</legend>
                    {schedules}
                    {errorMessage}
                </section>
            </div>
        )
    }
}

export default NotificationDetailDialog
