import React from 'react';
import './SettingsSelectHomePage.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PointList from '../../../components/DestinationList';
import RequestClient from '../../../common/requestClient';
import BackButton from '../../../components/BackButton';


class SettingsSelectHomePage extends React.Component{
    constructor(props){
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }

    componentDidMount(){
        if(!Object.keys(this.props.vehicle).length){
            this.handleBack()
        }
    }
    
    handleBack(){
        this.props.handleSetHomePosition(null,-1)
        this.props.history.push("/settings/vehicles")
    }

    handleNext(event,pointId){
        this.props.handleSetHomePosition(pointId)
        this.props.history.push("/settings/vehicles/confirm")
    }
    render(){
        return(
            <div className='settings-select-home-root content-root'>
                <div className="settings-select-home-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="settings-select-home-label-frame">
                        <div className="settings-select-home-label">
                            <div className="settings-home-address">
                                <span className="settings-target-vehicle">{this.props.vehicle["vehicle_name"]}</span>
                                <span>のホームを選んでください</span>
                            </div>
                        </div>
                    </div>
                </div>
                <PointList pointList={this.props.pointList} handleNext={this.handleNext}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
    };
  };
export default connect(mapStateToProps,null)(withRouter(SettingsSelectHomePage));