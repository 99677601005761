import React from 'react';
import {setTabIndex} from '../../actions/systemAction';
import { connect } from 'react-redux';
import {getTabIndex} from '../../conf/urlInfo'
import { Route, Switch} from 'react-router-dom'
import SchedulesPage from './SchedulesPage';
import VehicleRunConfirmPage from '../vehicle_run/VehicleRunConfirmPage';
import VehicleRunCompletePage from '../vehicle_run/VehicleRunCompletePage';

class SchedulePage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
          vehicle:{},
          destinationPointId:-1,
          apiExecFlg:true
        }
        this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
        this.handleRunView = this.handleRunView.bind(this)
        this.handleRunViewComplete = this.handleRunViewComplete.bind(this)
        this.handleRunViewBack = this.handleRunViewBack.bind(this)
      }
    componentDidMount() {
        const index = getTabIndex("Schedule")
        this.props.dispatchSetTabId(index)
    }
    
    handleSetApiExecFlg(execFlg){
        this.setState({apiExecFlg:execFlg})
    }
    handleRunView(vehicleId,vehicleName,destinationPointId){
        this.setState({
            vehicle:{
                vehicle_name:vehicleName,
                vehicle_id:vehicleId
            },
            destinationPointId:destinationPointId
        })
        this.props.history.push({ pathname: "/schedules/vehicle_run/confirm", state: { ref: "/schedules" } })
    }
    handleRunViewComplete(){
        this.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/schedules/vehicle_run/complete", state: { ref: "/vehicle_run/confirm" } })
    }
    handleRunViewBack(){
        this.props.history.push('/schedules')
    }
    
    render(){
        return(
            <Switch>
                <Route exact path='/schedules'>
                    <SchedulesPage handleRunView={this.handleRunView}/>
                </Route>
                <Route exact path='/schedules/vehicle_run/confirm'>
                    <VehicleRunConfirmPage vehicle = {this.state.vehicle} destinationPointId={this.state.destinationPointId} handleNext={this.handleRunViewComplete}　handleBack={this.handleRunViewBack} backButtonName="戻る"/>
                </Route>
                <Route exact path='/schedules/vehicle_run/complete'>
                    <VehicleRunCompletePage vehicle = {this.state.vehicle} handleSetApiExecFlg={this.handleSetApiExecFlg} apiExecFlg={this.state.apiExecFlg}/>
                </Route>
            </Switch>
        )
    }
}
const mapStateToProps = state => {
    return { 
    };
  };
const mapDispatchToProps = dispatch=>({
    dispatchSetTabId:tabIndex=>dispatch(setTabIndex(tabIndex)),
})
export default connect(mapStateToProps,mapDispatchToProps)(SchedulePage)