import React from 'react';
import { withRouter } from 'react-router';
import Button from '../../components/buttons/WhiteButton';
import './SettingsMenuPage.css';

const settingsMenu = [
    {menuName : "ホーム設定" , link:"settings/vehicles"},
    {menuName : "パスワード変更" , link:"settings/password"},
    {menuName : "管理者パスワード変更" , link:"settings/admin_password"},
    {menuName : "走行環境変更" , link:"settings/sync_vehicles"},
    {menuName : "通知設定" , link:"settings/notification"},
]

class SettingsMenuPage extends React.Component{
    constructor(props){
        super(props)
        this.handleTop = this.handleTop.bind(this)
    }
    handleTop() {
        this.props.history.push("/")
      }
    handleMenu(link){
        this.props.history.push(link)
    }
    render(){
        return (
            <div className="settings-menu-root content-root">
                <div className="settings-menu-frame">
                    {settingsMenu.map((menu)=>{
                        return <Button key={menu.link} buttonName={menu.menuName} onClick={()=>this.handleMenu(menu.link)}></Button>
                    })}
                </div>
            </div>
        )
    }
}


export default withRouter(SettingsMenuPage)