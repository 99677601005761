import React, { useEffect } from 'react';
import {tabInfos, getTabIndex} from '../conf/urlInfo'
import {setTabIndex, setPointId, setPointList} from '../actions/systemAction';
import {updateLoginStatus} from '../actions/userAction';
import { connect } from 'react-redux';
import PointSelectDialog from './PointSelectDialog';
import RequestClient from '../common/requestClient';
import './Navigation.css'
import BasicIcon from './icons/BasicIcon';
import ConfirmDialog from './ConfirmDialog';
import AlertDialog from './AlertDialog';
import {logout} from "../common/AuthUtil";

const mapStateToProps = state => {
    return {
      tabIndex:state.systemReducer.tabIndex,
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
      isLoading:state.systemReducer.isLoading
    };
  };
  const mapDispatchToProps = dispatch=>({
    dispatchUpdateLoginStatus: () => dispatch(updateLoginStatus()),
    dispatchSetTabId:tabIndex=>dispatch(setTabIndex(tabIndex)),
    dispatchSetPointId:pointId=>dispatch(setPointId(pointId)),
    dispatchSetPointList:pointList=>dispatch(setPointList(pointList))
  })

function NavItem(props){
  const {fg, tabName, tabValue, selectedIndex, handleClick} = props
  const tabindex = getTabIndex(tabName)
  const selected = tabindex==selectedIndex
  var flexGrow=null
  if(fg){
    flexGrow = <div style={{flexGrow:"1"}}></div>
  }
  return(
    <>
    {flexGrow}
    <div className={`nav-item-${tabindex} ${ selected　? "nav-item-selected" : ""}`} onClick={()=>handleClick(tabindex)}>
      <div className={`nav-item-label-${tabindex} valign-text-middle`}>{tabValue}</div>
    </div>
    </>
      
  )
}

function getPointName(pointList,pointId){
  var pointName = ""
  for(var i=0;i<pointList.length;i++){
      if(pointList[i]["point_id"] == pointId){
          pointName =pointList[i]["name"] 
      }
  }
  return pointName
}

function PlaceButton(props) {
  const {dialogOpen} = props
  const [name, setName] = React.useState(getPointName(props.pointList,props.pointId));

  const pointExist = (pointId)=>{
    for(var i=0;i<props.pointList.length;i++){
      if(props.pointList[i]["point_id"] == pointId){
          return true
      }
    }
    return false
  }
  const handleClose = (pointId) =>{
    if(pointId && pointExist(pointId) ){
        props.dispatchSetPointId(pointId)
        setName(getPointName(props.pointList,pointId))
        props.history.push("/")
    }
    if((!pointId || !pointExist(pointId)) && props.pointId <0){
      props.setAlertOpen(true)
    }else{
      props.dialogOpen(false)
    }
  }

  useEffect(()=>{
    setName(getPointName(props.pointList,props.pointId))
  },[props.pointList,props.pointId])

  return(
    <>
    <div className="point-button-frame">
          <div className="point-button" onClick={()=>dialogOpen(true)}>
            <BasicIcon className="pos-icon" src="/pos_icon.svg"/>
                <div className="link-pos valign-text-middle">
                  {name}
                </div> 
                <BasicIcon className="iconsmallarrowdown" src="/arrow_down.svg"/>
              </div>
    </div>
    <PointSelectDialog open={props.open} onClose={handleClose} pointList={props.pointList} point_id={props.pointId}></PointSelectDialog>
    </>
  )
}

function MenuIcon(props) {
  return(
  <div className="menu-icon" onClick={props.logout}>
      <img src="/menu_icon.svg"/>
  </div>
  )
}

class Navigation extends React.Component{
  constructor(props){
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.dialogOpen = this.dialogOpen.bind(this)
    this.setLogoutOpen = this.setLogoutOpen.bind(this)
    this.onLogout = this.onLogout.bind(this)
    this.setAlertOpen = this.setAlertOpen.bind(this)
    this.state = {
      open:false,
      logoutOpen:false,
      alertOpen:false,
    }
  }

  componentDidMount(){
    // ローカルストレージの値と同期させる。
    this.props.dispatchSetPointId(localStorage.getItem("point_id"))
    this.getPointList()
    if(this.props.pointId < 0 ){
      this.setState({open:true})
    }
  }

  getPointList(){
    RequestClient.get(`/api/places`)
    .then(response => {
      if (response.status === 200) {
          return response.json()
      } else if(response.status >= 400){
        this.props.history.push({ pathname: "/error", state: {status: response.status} })
      }
    })
    .then(data => {
      if (data) {
        this.props.dispatchSetPointList(data.results)
        var open = true 
        for(var i=0;i<data.results.length;i++){
          if(data.results[i]["point_id"]==this.props.pointId){
            open=false
          } 
        }
        if(open){
          this.props.dispatchSetPointId(-1)
          this.setState({open:true})
        }
      }
    })
  }

  dialogOpen(isOpen){
    if(this.props.isLoading){
      return
    }
    this.setState({open:isOpen});
  }
  
  handleClick = (tabIndex) => {
    if(this.props.isLoading){
      return
    }
    this.props.dispatchSetTabId(tabIndex);
    this.props.history.push({pathname:tabInfos[tabIndex]["path"]})
  };

  logout =()=>{
    if(this.props.isLoading){
      return
    }
    this.setLogoutOpen(true)
  }
  setLogoutOpen(isOpen){
    this.setState({logoutOpen:isOpen});
  }
  onLogout(){
    logout();
    this.props.dispatchUpdateLoginStatus()
    this.props.history.push("/login")
  }
  setAlertOpen(isOpen){
    this.setState({alertOpen:isOpen});
  }

  mbNavigation(){
    const paths = this.props.location.pathname.split("/")  
    if (paths.length > 2 || paths.includes("call") || paths.includes("send")|| paths.includes("returnhome")){
      return ""
    }
    return(
      <div className="header">
        <div className="use-point-frame">
          <PlaceButton open= {this.state.open} dialogOpen={this.dialogOpen} setAlertOpen={this.setAlertOpen} {...this.props}/>
          <div style={{flexGrow:"1"}}></div>
          <MenuIcon logout={this.logout}/>
          <ConfirmDialog title="ログアウト" open={this.state.logoutOpen} setOpen={this.setLogoutOpen} onConfirm={this.onLogout}>ログアウトしますか？</ConfirmDialog>
          <AlertDialog title="使用場所" open={this.state.alertOpen} setOpen={this.setAlertOpen}>使用場所を選択してください。</AlertDialog>
        </div>
        <div className="menu-frame">
          <div className="nav-item-frame">
            {tabInfos.map((tabInfo, index)=>{
              if (index>0){
                return <NavItem fg={true} tabName={tabInfo["tabname"]} tabValue={tabInfo["tabvalue"]} key={tabInfo["path"]} selectedIndex={this.props.tabIndex} handleClick={this.handleClick}></NavItem>
              }
              return <NavItem tabName={tabInfo["tabname"]} tabValue={tabInfo["tabvalue"]} key={tabInfo["path"]} selectedIndex={this.props.tabIndex} handleClick={this.handleClick}></NavItem>
            }
            )}          
          </div>
        </div>
      </div>
    )
  }
  render(){
    const isMobile = window.innerWidth < 1024
    if(isMobile){
      return this.mbNavigation()
    }
    return (
      <>
      <div className="header">
        <div className="menu-frame">
          <div className="nav-item-frame">
          {tabInfos.map((tabInfo)=>
            <NavItem tabName={tabInfo["tabname"]} tabValue={tabInfo["tabvalue"]} key={tabInfo["path"]} selectedIndex={this.props.tabIndex} handleClick={this.handleClick}></NavItem>
          )}          
          </div>
        </div>
        <div style={{flexGrow:"1"}}></div>
        <PlaceButton open= {this.state.open} dialogOpen={this.dialogOpen} setAlertOpen={this.setAlertOpen} {...this.props}/>
        <div className="link-logout valign-text-middle" onClick={this.logout}>
          ログアウト
        </div>
        <ConfirmDialog title="ログアウト" open={this.state.logoutOpen} setOpen={this.setLogoutOpen} onConfirm={this.onLogout}>ログアウトしますか？</ConfirmDialog>
        <AlertDialog title="使用場所" open={this.state.alertOpen} setOpen={this.setAlertOpen}>使用場所を選択してください。</AlertDialog>
      </div>
      </>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)