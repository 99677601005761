/**
 * エリアマップバージョンに関する関数
 */
import RequestClient from "./requestClient";
import {getAreaMap} from "./AreaMap";
import {logout} from "./AuthUtil";

/**
 * フロントで保持しているエリアマップバージョンIDが最新か否かをチェックする。
 * @param changed_cb エリアマップバージョンIDが変わっていた際のコールバック関数（Reactコンポーネントからは画面遷移処理などを行う）
 * @param error_cb 処理に失敗した際のコールバック関数（Reactコンポーネントからは画面遷移処理などを行う）
 */
const checkAreaMapVersionId = (changed_cb, error_cb) => {
    const current_version = getAreaMap().getVersionId();
    RequestClient.get("/api/map/version")
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                error_cb(response);
            }
        })
        .then(data => {
            if (data) {
                if (current_version !== data["area_map_version_id"]) {
                    changed_cb();
                }
            }
        })
}

/**
 * フロントで保持しているエリアマップバージョンIDが最新でない場合の処理
 */
const areaMapVersionIdChanged = () => {
    localStorage.setItem("point_id", "-1");
    logout();
}

export {checkAreaMapVersionId, areaMapVersionIdChanged}
