import React from 'react';
import './SendSelectDestinationPage.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PointList from '../../components/DestinationList';
import BackButton from '../../components/BackButton';

class SendSelectDestinationPage extends React.Component{
    constructor(props){
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }

    componentDidMount(){
        if (typeof this.props.location.state === 'undefined' || !(this.props.location.state.ref == '/send'|| this.props.location.state.ref == '/send/confirm')) {
            this.props.history.replace('/');
        }
        if(!Object.keys(this.props.selectedVehicle).length){
            this.props.history.push("/send")
        }
    }
    
    handleBack(){
        this.props.history.push("/send")
    }
    
    handleNext(event,pointId){
        this.props.onItemClick(pointId)
        this.props.history.push("/send/confirm")
        
    }
    render(){
        return(
            <div className="send-select-destination-root content-root">
                <div className="send-select-destination-page">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="send-select-destination-label-frame">
                        <div className="send-select-destination-label">
                            <div className="send-destination-address">
                                <span className="send-target-vehicle">{this.props.selectedVehicle["vehicle_name"]}</span>
                                <span>の行き先を選んでください</span>
                            </div>
                        </div>
                    </div>
                </div>
                <PointList targetPointId={this.props.pointId} pointList={this.props.pointList} handleNext={this.handleNext}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
    };
  };
export default connect(mapStateToProps,null)(withRouter(SendSelectDestinationPage));