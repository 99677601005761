import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './ConfirmDialog.css'

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog className="confirm-dialog-root" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button className="cancel-button" variant="contained" onClick={() => setOpen(false)}>
          キャンセル
        </Button>
        <Button className="ok-button" variant="contained"
          onClick={()=> {
              setOpen(false);
              onConfirm();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;