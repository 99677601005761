import './InputRange.css'

/**
 * 範囲を表す（スライダー）コンポーネント
 *
 * html構造の説明
 *  * 全体をdiv.input-range-frameで囲んでいる。
 *  * div.input-range-frame配下のdivでラベルに紐づくコンテンツ全体を囲んでいる。
 *  * input要素の前後に必要に応じてキャプションをつけることができる。
 *  * キャプションが構造を持つ場合はスタイルの設定が必要になるだろう。
 *
 */
function InputRange(props) {
    const {min = "0", max = "10", step = "1", captionHead = "", captionBottom = ""} = props
    return (
        <div className="input-range-frame">
            <label htmlFor={props.id} className="input-range-label">
                {props.title}
            </label>
            <div>
                {captionHead}
                <input type={"range"}
                       id={props.id}
                       value={props.value}
                       onChange={props.handleChange}
                       min={min}
                       max={max}
                       step={step}/>
                {captionBottom}
            </div>
        </div>
    )
}

export default InputRange
