import React from 'react';
import VehicleIcon from "../icons/VehicleIcon";
import "./NotificationPresentation.css";
import {getNotificationAgent} from "../../workers/NotificationAgent";
import {getNotifiedSchedules} from "../../common/NotifiedSchedules";
import {getNotificationView} from "../../common/NotificationView";
import NotificationDetailDialog from "./NotificationDetailDialog";
import {getArrivedVehicles} from "../../common/ArrivedVehicles";
import {AudioPlayer, VEHICLE_ARRIVED, VEHICLE_DEPARTED} from "../../common/AudioPlayer";
import {getNotificationConfig} from "../../common/NotificationConfig";

/**
 * 発進通知機能
 * 発進通知があったことを知らせるコンポーネント
 */
class NotificationPresentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showIcon: getNotificationView().isIcon(),
            showDialog: false,
        };

        this.handleNotificationRaised = this.handleNotificationRaised.bind(this);
        this.handleIconClicked = this.handleIconClicked.bind(this);
        this.handleDialogClosed = this.handleDialogClosed.bind(this);

        getNotificationAgent().start();
    }

    componentDidMount() {
        getNotificationAgent().addListener(this.handleNotificationRaised);
    }

    componentWillUnmount() {
        getNotificationAgent().removeListener();
    }

    /**
     * ワーカーから通知イベントがあった際に実行するコールバック関数
     * @param e ワーカーからのメッセージ
     */
    handleNotificationRaised(e) {
        if (e.data.msg !== 'schedules') {
            return;
        }
        if (!e.data.hasOwnProperty('schedules')) {
            console.error("While a schedule-notification raised, no schedules included.");
            return;
        }

        const audioPlayer = new AudioPlayer();
        const ntfConfig = getNotificationConfig();

        // 発進通知があった場合
        if (ntfConfig.isDepartureOn()
            && (e.data.schedules.hasOwnProperty('departures'))
            && (Object.keys(e.data.schedules.departures).length > 0)
            && getNotifiedSchedules().putNewKeys(e.data.schedules.departures)) {
            // 新規通知があったことを意味する。
            // そのときは新規通知アイコンを表示する。
            getNotificationView().enableIcon();

            // 発進通知音声を登録
            audioPlayer.push(VEHICLE_DEPARTED);
        }
        // 到着通知があった場合
        if (ntfConfig.isArrivalOn()
            && (e.data.schedules.hasOwnProperty('arrival'))
            && getArrivedVehicles().putNewData(e.data.schedules.arrival)) {
            // 新規通知があったことを意味する。
            // そのときは新規通知アイコンを表示する。
            getNotificationView().enableIcon();

            // 到着通知音声を登録
            audioPlayer.push(VEHICLE_ARRIVED);
        }
        audioPlayer.play(ntfConfig.getVolume() / 100);

        const isIcon = getNotificationView().isIcon();
        this.setState((state) => {
            return {
                showIcon: isIcon
            };
        });
    }

    /**
     * 新規通知アイコンをクリックしたときに実行する。
     * @param e {Event} イベント
     */
    handleIconClicked(e) {
        getNotificationView().disableIcon();

        /** 検証用 */
        this.setState((state) => {
            return {
                showIcon: false,
                showDialog: true
            };
        })
    }

    /**
     * 通知詳細ダイアログが閉じられた時に実行する。
     * @param e {MouseEvent<HTMLElement>}
     */
    handleDialogClosed(e) {
        this.setState({
            showDialog: false
        });
    }

    renderIcon() {
        return (
            <div onClick={this.handleIconClicked}>
                <VehicleIcon className={"notification-presentation-vehicle"}/>
            </div>
        )
    }

    /**
     * 表示中のページがトップページか判定する。
     * @returns {boolean}
     */
    isTopPage() {
        // FIXME: this.props.locationをIDEが解決できない。
        return (this.props.location.pathname === '/');
    }

    /**
     * 通知アイコン表示要否を判定する。
     * @returns {boolean}
     */
    isIconRequired() {
        return (
            (this.state.showIcon === true)
            && (this.isTopPage() === true)
        );
    }

    /**
     * ダイアログを表示する場合はtrueを返す。
     * @returns {boolean}
     */
    isDialogRequired() {
        return (this.state.showDialog === true);
    }

    /**
     * 通知詳細ダイアログを返す。
     * @returns {JSX.Element}
     */
    renderDialog() {
        return (
            <NotificationDetailDialog onClose={this.handleDialogClosed}/>
        )
    }

    render() {
        let icon = <></>;
        let dialog = <></>;
        if (this.isIconRequired()) {
            icon = this.renderIcon();
        }
        if (this.isDialogRequired()) {
            dialog = this.renderDialog();
        }

        return (
            <section className={"notification-presentation"}>
                {icon}
                {dialog}
            </section>
        );
    }
}

export default NotificationPresentation
