import React from 'react';
import CallSelectDestinationPage from './CallSelectDestinationPage';
import { Route, Switch} from 'react-router-dom'
import CallConfirmPage from './CallConfirmPage';
import CallCompletePage from './CallCompletePage';

class CallPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            destinationPointId:-1,
            apiExecFlg:true
        }
        this.handleSetDestinationPoint = this.handleSetDestinationPoint.bind(this)
        this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
    }
    handleSetDestinationPoint(destinationPointId){
        this.setState({destinationPointId:destinationPointId})
    }
    handleSetApiExecFlg(execFlg){
        this.setState({apiExecFlg:execFlg})
    }
    render(){
        return <Switch>
            <Route exact path='/call'>
                <CallSelectDestinationPage destinationPointId={this.state.destinationPointId} onItemClick={this.handleSetDestinationPoint}></CallSelectDestinationPage>
            </Route>
            <Route exact path='/call/confirm'>
                <CallConfirmPage destinationPointId={this.state.destinationPointId} handleSetApiExecFlg={this.handleSetApiExecFlg} ></CallConfirmPage>
            </Route>
            <Route exact path='/call/complete'>
                <CallCompletePage destinationPointId={this.state.destinationPointId} apiExecFlg={this.state.apiExecFlg} handleSetApiExecFlg={this.handleSetApiExecFlg} ></CallCompletePage>
            </Route>
        </Switch>
    }
}

export default CallPage;