import React from 'react';
import './SettingsPasswordInputPage.css';
import { withRouter } from 'react-router';
import Button from '../../../components/buttons/GreenButton';
import BackButton from '../../../components/BackButton';
import InputPassword from '../../../components/InputPassword';

class SettingsPasswordInputPage extends React.Component{
    
    constructor(props){
        super(props)
        this.handleSettingsMenu = this.handleSettingsMenu.bind(this)
        this.handleNewPasswordPreview = this.handleNewPasswordPreview.bind(this)
        this.handleAdminPasswordPreview = this.handleAdminPasswordPreview.bind(this)
        this.state={
            new_password_preview:false,
            admin_password:false
        }
    }

    componentDidMount() {
        this.props.clear()
    }
    
    handleSettingsMenu() {
        this.props.history.push({ pathname: "/settings", state: { ref: "/settings/admin_password" } })
    }
    handleNewPasswordPreview(preview){
        this.setState(
             {
                 new_password_preview:preview
            }
        )
    }
    handleAdminPasswordPreview(preview){
        this.setState(
            {
                admin_password_preview:preview
            }
        )
    }
    
    render(){
        return(
            <div className="settings-password-input-root content-root">
                <div className="settings-password-input-page">
                    <BackButton handleBack={this.handleSettingsMenu}/>
                    <div className="settings-password-input-label-frame">
                        <div className="settings-password-input-label">パスワード変更</div>
                    </div>
                </div>
                <InputPassword handleChange={this.props.handleOnChange} preview={this.state.new_password_preview} id="new_password" type="password" title="新しいパスワード" placeholder="************" handlePreview={this.handleNewPasswordPreview}/>
                <InputPassword handleChange={this.props.handleOnChange} preview={this.state.admin_password_preview}  id="admin_password" type="password" title="管理者パスワード" placeholder="************" handlePreview={this.handleAdminPasswordPreview}/>
                <Button buttonName="変更" onClick={this.props.handleChange}/>
            </div>
        )
    }
}

export default withRouter(SettingsPasswordInputPage)