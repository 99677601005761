import React from 'react';
import {Route, Switch} from 'react-router-dom'
import { withRouter } from 'react-router';
import SettingsPasswordCompletePage from './SettingsPasswordCompletePage';
import SettingsPasswordInputPage from './SettingsPasswordInputPage';
import ConfirmDialog from '../../../components/ConfirmDialog';
import AlertDialog from '../../../components/AlertDialog';

class SettingsPasswordPage extends React.Component{

    constructor(props) {
        super(props)
        this.state ={
            admin_password:"",
            new_password:"",
            confirmOpen:false,
            alertOpen:false,
            alertMsg:"",
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.setConfirmOpen = this.setConfirmOpen.bind(this)
        this.onConfirm = this.onChange.bind(this)
        this.setAlertOpen = this.setAlertOpen.bind(this)
        this.setAlertMsg = this.setAlertMsg.bind(this)
        this.clear = this.clear.bind(this)
    }
    
    validation(){
        const regAlphabet = /[a-zA-Z]/
        const regNumber = /[0-9]/
        if(!this.state.admin_password){
            this.setAlertMsg("管理者パスワードが入力されていません")
        }else if(!this.state.new_password){
            this.setAlertMsg("新しいパスワードが入力されていません")
        }else if(this.state.new_password.length<8){
            this.setAlertMsg("新しいパスワードは8文字以上である必要があります")
        }else if(!regAlphabet.test(this.state.new_password)){
            this.setAlertMsg("新しいパスワードは半角英字を含む必要があります")
        }else if(!regNumber.test(this.state.new_password)){
            this.setAlertMsg("新しいパスワードは半角数字を含む必要があります")
        }else if(this.state.new_password.match(/[^A-Za-z0-9]+/)){
            this.setAlertMsg("新しいパスワードは半角英数字のみである必要があります")
        }else{
            return true;
        }
        this.setAlertOpen(true)
        return false;
    }
    handleChange(e) {
        e.preventDefault();
        if(this.validation()){
            this.setConfirmOpen(true)
        }   
    }
    setConfirmOpen(isOpen){
        this.setState({confirmOpen:isOpen});
    }
    onChange(){
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/settings/password/complete", state: { ref: "/settings/password" } })
    }
 
    handleOnChange(e){
       this.setState({ [e.target.id]: e.target.value});
    }
    setAlertOpen(isOpen){
        this.setState({alertOpen:isOpen});
    }
    setAlertMsg(msg){
        this.setState({alertMsg:msg});
    }

    clear(){
        this.setState({new_password:"",admin_password:""})
    }

    render(){
        return (
            <Switch>
              <Route exact path='/settings/password'>
                <SettingsPasswordInputPage handleOnChange={this.handleOnChange} handleChange={this.handleChange} clear={this.clear}/>
                <ConfirmDialog title="パスワード変更" open={this.state.confirmOpen} setOpen={this.setConfirmOpen} onConfirm={this.onConfirm}>
                  パスワードを変更します。よろしいですか？
                </ConfirmDialog>
                <AlertDialog title="パスワード変更" open={this.state.alertOpen} setOpen={this.setAlertOpen}>{this.state.alertMsg}</AlertDialog>
              </Route>
              <Route exact path='/settings/password/complete'>
                  <SettingsPasswordCompletePage handleSetApiExecFlg={this.props.handleSetApiExecFlg} apiExecFlg={this.props.apiExecFlg} admin_password={this.state.admin_password} new_password={this.state.new_password} />
              </Route>
            </Switch>
        )
    }
}

export default withRouter(SettingsPasswordPage)