import InputRange from '../InputRange';

const NOTIFICATION_ON = "1"
const NOTIFICATION_OFF = "0"

function isNotificationOn(value) {
    return value === NOTIFICATION_ON
}

function NotificationSwitch(props) {
    return(
        <InputRange id={props.id}
                    title={props.title}
                    value={props.value ? NOTIFICATION_ON : NOTIFICATION_OFF}
                    handleChange={props.handleChange}
                    min={"0"}
                    max={"1"}
                    step={"1"}
                    captionHead={props.captionHead}
                    captionBottom={props.captionBottom}/>
    )
}

export {NotificationSwitch, isNotificationOn, NOTIFICATION_ON, NOTIFICATION_OFF}
