const host = process.env.REACT_APP_URL

function getJWT(itemName) {
    return localStorage.getItem(itemName)
  }

const request =(url,data,method) =>{
    url = host + url;
    const jwt = getJWT('eva_calls_jwt');
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization":`JWT ${jwt}`
      };
    if(method === "GET"){
        return fetch(url,{headers:headers})
    }else if(method === "POST"||method === "PATCH"){
        const body = JSON.stringify(data);
        return fetch(url,{method:method,headers:headers,body:body})
    }
}

const RequestClient ={
    get:function(url){
        return request(url,null,"GET")
    },
    post:function(url,data){
        return request(url,data,"POST")
    },
    patch:function(url,data){
        return request(url,data,"PATCH")
    }
}
export default RequestClient;