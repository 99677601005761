import React from 'react';
import SendSelectVehiclePage from './SendSelectVehiclePage';
import {Route, Switch} from 'react-router-dom'
import SendSelectDestinationPage from './SendSelectDestinationPage';
import SendConfirmPage from './SendConfirmPage';
import SendCompletePage from './SendCompletePage';
import VehicleRunConfirmPage from '../vehicle_run/VehicleRunConfirmPage';
import VehicleRunCompletePage from '../vehicle_run/VehicleRunCompletePage';
import { withRouter } from 'react-router';

class SendPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            vehicle:{},
            destinationPointId:-1,
            apiExecFlg:true
        }
        this.handleSetVehicle = this.handleSetVehicle.bind(this)
        this.handleSetDestinationPoint = this.handleSetDestinationPoint.bind(this)
        this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
        this.handleRunViewComplete = this.handleRunViewComplete.bind(this)
        this.handleRunViewBack = this.handleRunViewBack.bind(this)
    }
    

    handleSetVehicle(vehicle){
        this.setState({vehicle:vehicle})
    }
    
    handleSetDestinationPoint(destinationPointId){
        this.setState({destinationPointId:destinationPointId})
    }

    handleSetApiExecFlg(execFlg){
        this.setState({apiExecFlg:execFlg})
    }
    
    handleRunViewComplete(){
        this.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/send/vehicle_run/complete", state: { ref: "/vehicle_run/confirm" } })
    }
    handleRunViewBack(){
        this.props.history.push('/')
    }

    render(){
        return <Switch>
            <Route exact path='/send'>
                <SendSelectVehiclePage handleSetVehicle={this.handleSetVehicle}/>
            </Route>
            <Route exact path='/send/destination'>
                <SendSelectDestinationPage selectedVehicle={this.state.vehicle} destinationPointId={this.state.destinationPointId} onItemClick={this.handleSetDestinationPoint} />
            </Route>
            <Route exact path='/send/confirm'>
                <SendConfirmPage vehicle={this.state.vehicle} destinationPointId={this.state.destinationPointId} handleSetApiExecFlg={this.handleSetApiExecFlg} />
            </Route>
            <Route exact path='/send/complete'>
                <SendCompletePage vehicle = {this.state.vehicle} destinationPointId={this.state.destinationPointId} apiExecFlg={this.state.apiExecFlg} handleSetApiExecFlg={this.handleSetApiExecFlg} ></SendCompletePage>
            </Route>
            <Route exact path='/send/vehicle_run/confirm'>
                <VehicleRunConfirmPage vehicle = {this.state.vehicle} destinationPointId={this.state.destinationPointId} handleNext={this.handleRunViewComplete} handleBack={this.handleRunViewBack} backButtonName="TOPへ" />
            </Route>
            <Route exact path='/send/vehicle_run/complete'>
                    <VehicleRunCompletePage vehicle = {this.state.vehicle}  handleSetApiExecFlg={this.handleSetApiExecFlg} apiExecFlg={this.state.apiExecFlg}/>
            </Route>
        </Switch>
    }
}

export default withRouter(SendPage);