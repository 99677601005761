import './InputPassword.css'

function InputPassword(props){
    const {title, prefixName, id, placeholder, handlePreview, preview} = props
    return(
        <div className="password-frame">
            <div className="password-label">{title}</div>
            <div className="password-input-frame">
                <input id={id} type={preview? "text":"password"} autoComplete="off" onChange={props.handleChange} placeholder={placeholder}/>
                <div onClick={()=>handlePreview(!preview)}>表示</div>
            </div>
        </div>
    )
}

export default InputPassword