import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom'
import {connect} from 'react-redux';
import Navigation from '../components/Navigation';
import ReservationPage from './reservations/ReservationPage';
import SchedulePage from './schedules/SchedulePage';
import OneTripPage from './OneTripPage';
import SettingsPage from './settings/SettingsPage';
import NotificationPresentation from "../components/notifications/NotificationPresentation";

class PrivatePage extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.is_LoggedIn) {
            return (
                <>
                    <Navigation  {...this.props}></Navigation>
                    <NotificationPresentation {...this.props}/>
                    <Switch>
                        <Route path='/reservations' component={ReservationPage}/>
                        <Route path='/schedules' component={SchedulePage}/>
                        <Route path='/settings' component={SettingsPage}/>
                        <Route path='/' component={OneTripPage}/>
                    </Switch>
                </>
            );
        }
        return <Redirect to={'/login'}/>
    }
}

const mapStateToProps = state => {
    return {
        is_LoggedIn: state.userReducer.is_LoggedIn
    };
};

export default connect(mapStateToProps, null)(PrivatePage);