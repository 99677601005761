import React from 'react';
import { connect } from 'react-redux';
import './ReturnConfirmPage.css';
import { withRouter } from 'react-router';
import GreenButton from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import EnableArrowIcon from '../../components/icons/EnableArrowIcon';
import EnableTargetPoint from '../../components/TargetPoints/EnableTargetPoint';

class ReturnConfirmPage extends React.Component{

    constructor(props) {
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    
    componentDidMount(){
        if(!Object.keys(this.props.vehicle).length){
            this.props.history.push("/return")
        }else if(this.props.destinationPointId < 0){
            this.props.history.push("/return/destination")
        }
        
    }

    handleBack() {
        this.props.history.push({ pathname: "/returnhome", state: { ref: "/returnhome/confirm" } })
    }
    handleNext() {
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname: "/returnhome/complete", state: { ref: "/returnhome/confirm" } })
    }
    getPointName(pointList, pointId){
        var pointName = ""
        for(var i=0;i<pointList.length;i++){
            if(pointList[i]["point_id"] == pointId){
                pointName =pointList[i]["name"] 
            }
        }
        return pointName
    }
    render(){
        return(
            <div className='return-confirm-root content-root'>
                <div className="return-confirm-title">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="return-confirm-text-frame">
                        <div className="return-confirm-text">
                            <span className="return-confirm-text-vehicle">
                                {this.props.vehicle["vehicle_name"]}
                            </span>
                            <span>をホームに戻しますか？</span>
                        </div>
                    </div>
                </div>
                <div className="return-confirm-content-frame">
                    <EnableTargetPoint targetLabel="現在地">{this.getPointName(this.props.pointList,this.props.pointId)}</EnableTargetPoint>
                    <EnableArrowIcon className="return-arrow-icon"/>
                    <EnableTargetPoint targetLabel="ホーム">{this.getPointName(this.props.pointList,this.props.vehicle.home_position_id)}</EnableTargetPoint>
                </div>
                <GreenButton buttonName="ホームに戻す" onClick={this.handleNext}/>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        pointList: state.systemReducer.pointList,
        pointId:state.systemReducer.pointId,
    };
};

export default connect(mapStateToProps, null)(withRouter(ReturnConfirmPage));