import BasicIcon from '../icons/BasicIcon';

function NotificationVolumeMax(props) {
    return(
        <BasicIcon
            className={props.className}
            src="/sound_volume_max.svg"
            alt={"maximize volume"}
            onClick={props.onClick}
        />
    )
}

export default NotificationVolumeMax
