/**
 * LocalStorageContext class
 * 保存するデータはPlainオブジェクトを想定
 */
import PersistentContext from "./PersistentContext";

class LocalStorageContext extends PersistentContext {
    constructor(name) {
        super(name);
    }

    initialize() {
        this.save({})
    }

    restore() {
        return JSON.parse(localStorage.getItem(this.getName()))
    }

    save(data) {
        localStorage.setItem(this.getName(), JSON.stringify(data))
    }

    update(key, value) {
        const data = this.restore();
        data[key] = value;
        this.save(data);
    }

    getByKey(key) {
        const data = this.restore();
        return data[key];
    }
}

export default LocalStorageContext
