import './BasicIcon.css';

function BasicIcon(props) {
    const {className, src, onClick, alt} = props
    return(
        <div className={`icon ${className}`} onClick={onClick}>
            <img style={{width:"100%"}} src={src} alt={alt}/>
        </div>
    )
}

export default BasicIcon