import React from 'react';
import './VehicleSelectDialog.css';

function VehiclePulldownList(props) {
    const {selectedVehicle, vehicleList, handleSelected, handleCancel} = props
    const VehicleComponents = vehicleList.map((vehicle)=>{
        if(vehicle["vehicle_id"]==selectedVehicle["vehicle_id"]){
            return <PullDownSelectedItem onClick={()=>handleSelected(vehicle)} key={vehicle["vehicle_id"]} vehicle={vehicle}/>
        }else{
            return <PullDownItem onClick={()=>handleSelected(vehicle)} key={vehicle["vehicle_id"]} vehicle={vehicle}/>
        }
    })
    return(
        <div className="pulldown-list-overray" onClick={handleCancel} >
            <div className="vehicle-pulldown-list" onClick={(e) => e.stopPropagation()}>
                {VehicleComponents}
            </div>
        </div>
    )
    
}

function PullDownSelectedItem(props) {
    const {vehicle, onClick} = props

    return (
        <div className="vehicle-pulldown-selected-item-bg" onClick={onClick}>
            <div className="vehicle-pulldown-selected-item">
                <img className="vehicle-pulldown-selected-item-icon" src="/check_icon.svg"/>
                <div className="vehicle-pulldown-selected-item-text">{vehicle["vehicle_name"]}</div>
            </div>

        </div>
    )
}
function PullDownItem(props) {
    const {vehicle, onClick} = props
    return (
        <div className="vehicle-pulldown-item" onClick={onClick}>
            <div className="vehicle-pulldown-item-text">
                {vehicle["vehicle_name"]}
            </div>
        </div>
    )
}

export default VehiclePulldownList