import LocalStorageContext from "./LocalStorageContext";

/**
 * 通知された発進通知のスケジュールIDと発進時刻を管理する。
 * FMSスケジュールIDをキー、発進時刻を値とするハッシュテーブル。
 */
class NotifiedSchedules extends LocalStorageContext {
    constructor() {
        super("notified_schedules");
    }

    /**
     * 受け取った通知スケジュールがデータベースに登録されていない場合は追加する。
     * 戻り値がtrueの場合、新規通知があったことを意味する。
     * 通知詳細APIのスケジュールを受け取ることはできないことに注意する。
     * @param schedules {{}} 通知チェックAPIのスケジュール
     * @returns {boolean} 追加したエントリが1件以上あればtrue。
     */
    putNewKeys(schedules) {
        let foundNewKey = false;
        const db = this.restore();
        for (const [key, val] of Object.entries(schedules)) {
            if (db[key] === undefined) {
                db[key] = val;
                foundNewKey = true;
            }
        }
        if (foundNewKey) {
            this.save(db);
        }
        return foundNewKey;
    }

    /**
     * 受け取った通知スケジュールがデータベースに登録されている場合はそのキーと値のペアを返す。
     * 発進車両の詳細情報を表示する際、同じスケジュールIDならばデータベースに登録されている方の時刻を表示させるために使用する。
     * @param schedules {{}} 受け取ったスケジュール
     * @returns {{}} スケジュールIDと発進日時のペア
     */
    getByKeys(schedules) {
        let found = {};
        const db = this.restore();
        Object.keys(schedules).forEach((key) => {
            if (db[key] !== undefined) {
                found[key] = db[key];
            }
        })
        return found;
    }

    /**
     * 受け取った通知スケジュール以外のキーと値のペアをデータベースから削除する。
     * 通知詳細を表示した後、それ以前の古いスケジュールをデータベースから削除しデータベースのサイズを小さくするために使用する。
     * 通知詳細API戻り値のスケジュールを渡す場合は、値を通知詳細オブジェクトから発進時刻にmapしてから渡す。
     * @param schedules {{}} 受け取ったスケジュール
     */
    clearExceptForKeys(schedules) {
        // 新データベース
        const newDb = {};
        // 既存データベース
        const currentDb = this.restore();
        Object.keys(schedules).forEach((scheduleId) => {
            if (currentDb[scheduleId] !== undefined) {
                // 既存データベースにキーがある場合はそれを採用する。
                newDb[scheduleId] = currentDb[scheduleId];
            } else {
                // 既存データベースにキーがない場合は受け取ったスケジュールの方を採用する。
                newDb[scheduleId] = schedules[scheduleId];
            }
        });
        this.save(newDb);
    }
}

const notifiedSchedules = new NotifiedSchedules();

// 発進通知を使用しない場合でもロードされた時点でストレージに初期化データを保存する。
if (notifiedSchedules.restore() === null) {
    notifiedSchedules.initialize();
}

function getNotifiedSchedules() {
    return notifiedSchedules;
}

export {getNotifiedSchedules}
