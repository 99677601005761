import React from 'react';
import {Route, Switch} from 'react-router-dom'
import { withRouter } from 'react-router';
import SettingsSyncVehiclesInputPage from './SettingsSyncVehiclesInputPage';
import SettingsSyncVehiclesCompletePage from './SettingsSyncVehiclesCompletePage';

class SettingsSyncVehiclesPage extends React.Component{

    constructor(props) {
        super(props)
    }

    
    
    render(){
        return (
            <Switch>
                <Route exact path='/settings/sync_vehicles'>
                    <SettingsSyncVehiclesInputPage　handleSetApiExecFlg={this.props.handleSetApiExecFlg}/>
                </Route>
                <Route exact path='/settings/sync_vehicles/complete'>
                    <SettingsSyncVehiclesCompletePage handleSetApiExecFlg={this.props.handleSetApiExecFlg} apiExecFlg={this.props.apiExecFlg}/>
                </Route>
            </Switch>
        )
    }
}

export default withRouter(SettingsSyncVehiclesPage);