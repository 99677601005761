/* eslint no-restricted-globals: ["error"] */

export default () => {
    let intervalId = undefined;

    function intervalCallback(url, jwt, point_id, departure, arrival) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": "JWT " + jwt
        };
        const requestURL = url + '?point_id=' + encodeURIComponent(point_id) + '&departure=' + encodeURIComponent(departure) + '&arrival=' + encodeURIComponent(arrival);
        const request = new Request(requestURL);
        fetch(request, {headers: headers})
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    console.debug("notification-check-fetch response not ok.", response);
                    postMessage({
                        msg: 'error',
                        status: response.status
                    });
                }
            })
            .then(data => {
                if ((data) && (data.schedules)) {
                    if (Object.keys(data.schedules).length > 0) {
                        postMessage({
                            msg: 'schedules',
                            schedules: data.schedules
                        });
                    }
                }
            })
            .catch((reason) => {
                console.debug("notification-check-fetch failed.", reason);
                postMessage({
                    msg: 'error',
                    status: reason
                });
            });
    }

    function start(e) {
        if (intervalId !== undefined) {
            // 既に実施しているポーリングがある。
            throw new Error('Polling already running, intervalId: ' + intervalId)
        }
        intervalId = setInterval(intervalCallback, e.data.interval, e.data.url, e.data.jwt, e.data.point_id, e.data.departure, e.data.arrival);
    }

    function stop(e) {
        if (intervalId === undefined) {
            // 実施しているポーリングがない。
            throw new Error('No polling running.')
        }
        clearInterval(intervalId)
        intervalId = undefined;
    }

    self.addEventListener(
        'message',
        function (ev) {
            console.debug("worker event fired", ev.data)

            try {
                if (ev.data.action === 'start') {
                    start(ev)
                    postMessage({
                        msg: 'polling started: ' + intervalId
                    });
                }

                if (ev.data.action === 'stop') {
                    stop(ev)
                    postMessage({
                        msg: 'polling stopped'
                    });
                }
            } catch (err) {
                console.debug(err.message, ev);
                postMessage({
                    msg: err.message
                });
            }
        },
        false
    );
};
