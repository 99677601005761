import React from 'react';
import {setTabIndex} from '../../actions/systemAction';
import { connect } from 'react-redux';
import {getTabIndex} from '../../conf/urlInfo'
import ReservationListPage from './ReservationListPage';
import { Route, Switch} from 'react-router-dom'
import ReservationCancelConfirmPage from './ReservationCancelConfirmPage';
import ReservationCancelCompletePage from './ReservationCancelCompletePage';

class ReservationPage extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        reservation:{},
        apiExecFlg:true
      }
      this.handleCancel = this.handleCancel.bind(this)
      this.handleSetApiExecFlg = this.handleSetApiExecFlg.bind(this)
    }
    componentDidMount(){
        const index = getTabIndex("Reservation")
        this.props.dispatchSetTabId(index)
        

    }
    handleCancel(reservation){
      this.setState({reservation:reservation})
      this.props.history.push('/reservations/cancel/confirm')
    }
    
    handleSetApiExecFlg(execFlg){
      this.setState({apiExecFlg:execFlg})
    }

    render(){
        return(
        <Switch>
          <Route exact path='/reservations'>
            <ReservationListPage handleCancel={this.handleCancel}/>
          </Route>
          <Route exact path='/reservations/cancel/confirm'>
            <ReservationCancelConfirmPage reservation={this.state.reservation} handleSetApiExecFlg={this.handleSetApiExecFlg} />
          </Route>
          <Route exact path='/reservations/cancel/complete'>
            <ReservationCancelCompletePage reservation={this.state.reservation} handleSetApiExecFlg={this.handleSetApiExecFlg} apiExecFlg={this.state.apiExecFlg} />
          </Route>
        </Switch>
        )
    }
}
const mapStateToProps = state => {
    return { 
    };
  };
const mapDispatchToProps = dispatch=>({
    dispatchSetTabId:tabIndex=>dispatch(setTabIndex(tabIndex)),
  })
export default connect(mapStateToProps,mapDispatchToProps)(ReservationPage)