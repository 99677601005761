import LocalStorageContext from "./LocalStorageContext";

const NOTIFICATION_INIT_VALUE = false;
const NOTIFICATION_INIT_VOLUME = 20;
const NOTIFICATION_ARRIVAL_INIT_VALUE = false;

const SWITCH_KEY_NAME = "ntfOn";
const VOLUME_KEY_NAME = "ntfVolume";
const SWITCH_ARRIVAL_KEY_NAME = "ntfArrivalOn";

class NotificationConfig extends LocalStorageContext {
    constructor() {
        super("ntf_config");
    }

    initialize() {
        const data = {
            [SWITCH_KEY_NAME]: NOTIFICATION_INIT_VALUE,
            [SWITCH_ARRIVAL_KEY_NAME]: NOTIFICATION_ARRIVAL_INIT_VALUE,
            [VOLUME_KEY_NAME]: NOTIFICATION_INIT_VOLUME,
        }
        this.save(data);
    }

    isAllOff() {
        return (!this.isDepartureOn()) && (!this.isArrivalOn());
    }

    isAnyOn() {
        return (this.isDepartureOn()) || (this.isArrivalOn());
    }

    isDepartureOn() {
        return this.getByKey(SWITCH_KEY_NAME);
    }

    isArrivalOn() {
        return this.getByKey(SWITCH_ARRIVAL_KEY_NAME);
    }

    getVolume() {
        return this.getByKey(VOLUME_KEY_NAME);
    }

    setDepartureOn() {
        this.update(SWITCH_KEY_NAME, true);
    }

    setDepartureOff() {
        this.update(SWITCH_KEY_NAME, false);
    }

    setArrivalOn() {
        this.update(SWITCH_ARRIVAL_KEY_NAME, true);
    }

    setArrivalOff() {
        this.update(SWITCH_ARRIVAL_KEY_NAME, false);
    }

    setVolume(volume) {
        this.update(VOLUME_KEY_NAME, volume);
    }
}

const config = new NotificationConfig();

// ロードされた時点でストレージに初期化データを保存する。
if (config.restore() === null) {
    config.initialize();
}
// 通知機能リリース後、到着通知リリース前に使っていた場合、到着通知ON/OFF設定がundefinedになるのでfalseで保存する。
if (config.isArrivalOn() === undefined) {
    config.setArrivalOff();
}

function getNotificationConfig() {
    return config;
}

export {getNotificationConfig, SWITCH_KEY_NAME, VOLUME_KEY_NAME, SWITCH_ARRIVAL_KEY_NAME}
