import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setIsLoading } from '../../actions/systemAction';
import RequestClient from '../../common/requestClient';
import Loading from '../../components/Loading';
import {CautionMsg,SuccessMsg} from '../../components/SimpleMsges';

const STATUS_API_CALLING = 0
const STATUS_API_SUCCEEDED = 1
const STATUS_API_FAILED = 2
const STATUS_API_PRECONDITION_FAILED = 3

function SucceededComponent(props){
    return (
        <SuccessMsg prefixName="" buttonName="TOPへ" onClick={props.handleTop} message="車両を発進しました">
        </SuccessMsg>
    )
}
function PreconditionFailedComponent(props){
    return(
        <CautionMsg prefixName="" buttonName="戻る" onClick={props.handleBack} message={"発進できませんでした\n車両が発進可能な状態か確認ください"}>
        </CautionMsg>
    )
}
function FailedComponent(props){
    return(
        <CautionMsg prefixName="" buttonName="TOPへ" onClick={props.handleTop} message="車両の発進ができませんでした">
        </CautionMsg>
    )
}

class VehicleRunCompletePage extends React.Component{

    constructor(props){
        super(props)
        this.state = { status: STATUS_API_CALLING }
        this.handleTop = this.handleTop.bind(this)
        this.handleBack = this.handleBack.bind(this)
    }
    componentDidMount(){
        if (typeof this.props.location.state === 'undefined' || this.props.location.state.ref !== '/vehicle_run/confirm' || this.props.apiExecFlg) {
            this.props.history.replace('/');
        }else{
            this.startVehicle()
        } 
    }
    componentWillUnmount() {
        this.props.dispatchSetIsLoading(false)
    }

    handleTop(){
        this.props.history.push("/")
    }
    handleBack(){
        this.props.history.goBack()
    }
    startVehicle() {
        this.props.handleSetApiExecFlg(true)
        this.props.dispatchSetIsLoading(true)
        const url = "/api/start_vehicle"
        const data = {
                vehicle_id: this.props.vehicle.vehicle_id
        };
        RequestClient.post(url, data)
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else if(response.status === 409){
                        this.setState({
                            status:STATUS_API_PRECONDITION_FAILED
                        })
                    } else if(response.status >= 400){
                        this.setState({
                            status:STATUS_API_FAILED
                        })
                    }
                })
                .then(data => {
                    if (data) {
                        this.setState({
                            status:STATUS_API_SUCCEEDED
                        })
                    }
                }).finally(() => { this.props.dispatchSetIsLoading(false) })
        
        
    }
    render(){
        var component = ""
        if (this.state.status == STATUS_API_CALLING) {
            component = <Loading/>
        }else if(this.state.status == STATUS_API_SUCCEEDED){
            component = <SucceededComponent handleTop={this.handleTop}/>
        }else if(this.state.status == STATUS_API_PRECONDITION_FAILED){
            component = <PreconditionFailedComponent handleBack={this.handleBack}/>
        }else{
            component = <FailedComponent handleTop={this.handleTop}/>
        }
        return(
            <div className='vehicle-run-complete-root content-root'>
                {component}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => ({
    dispatchSetIsLoading: isLoading => dispatch(setIsLoading(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehicleRunCompletePage));