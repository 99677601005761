import LocalStorageContext from "./LocalStorageContext";

/**
 * 通知アイコンの表示要否を表す。
 * 値がtrueのとき、通知アイコンを表示する。
 * @type {string}
 */
const ICON_KEY_NAME = "icon";

/**
 * 通知のビューに関する状態を管理する。
 */
class NotificationView extends LocalStorageContext {
    constructor() {
        super("ntf_view");
    }

    initialize() {
        const data = {
            [ICON_KEY_NAME]: false,
        }
        this.save(data);
    }

    /**
     * アイコン表示要否を判定する。
     * @returns {boolean} trueのときアイコン表示する。
     */
    isIcon() {
        return this.getByKey(ICON_KEY_NAME);
    }

    /**
     * アイコンを非表示状態にする。
     */
    disableIcon() {
        this.setIcon(false);
    }

    /**
     * アイコンを表示状態にする。
     */
    enableIcon() {
        this.setIcon(true);
    }

    /**
     * アイコン表示要否を設定する。
     * @param isIcon {boolean} アイコン表示のときtrue。
     */
    setIcon(isIcon) {
        this.update(ICON_KEY_NAME, isIcon);
    }
}

const view = new NotificationView();

// ロードされた時点でストレージに初期化データを保存する。
if (view.restore() === null) {
    view.initialize();
}

function getNotificationView() {
    return view;
}

export {getNotificationView}
