import React from 'react';
import { withRouter } from 'react-router';
import Button from '../../../components/buttons/GreenButton';
import "./SettingsSyncVehiclesInputPage.css"
import BackButton from '../../../components/BackButton';
import {areaMapVersionIdChanged, checkAreaMapVersionId} from "../../../common/AreaMapUtil";

class SettingsSyncVehiclesInputPage extends React.Component{

    constructor(props) {
        super(props)
        this.handleSettingsMenu = this.handleSettingsMenu.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(){
        checkAreaMapVersionId(() => {
            areaMapVersionIdChanged();
            this.props.history.push({ pathname: "/error", state: {status: 409} })
        }, (response) => {
            this.props.history.push({ pathname: "/error", state: {status: response.status} })
        })
    }

    handleSettingsMenu() {
        this.props.history.push("/settings")
    }
    handleChange(){
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({ pathname:"/settings/sync_vehicles/complete" , state: { ref: "/settings/sync_vehicles" }})
    }

    render(){
        return (
            <div className="settings-sync-vehicle-root content-root">
                <div className="settings-sync-vehicle-page">
                    <BackButton handleBack={this.handleSettingsMenu}/>
                    <div className="settings-sync-vehicle-label-frame">
                        <div className="settings-sync-vehicle-label">車両と走行環境の同期を行います。<br/>よろしいですか？</div>
                    </div>
                </div>
                <Button buttonName="同期を行う" onClick={this.handleChange}/>
            </div>
        )
    }
}

export default withRouter(SettingsSyncVehiclesInputPage);