import React from 'react';
import {setTabIndex} from '../actions/systemAction';
import {connect} from 'react-redux';
import {getTabIndex} from '../conf/urlInfo'
import TopPage from './TopPage';
import ReturnPage from './return/ReturnPage';
import CallPage from './call/CallPage';
import SendPage from './send/SendPage';
import { Route, Switch} from 'react-router-dom'

class OneTripPage extends React.Component{
    componentDidMount(){
        const index = getTabIndex("Top")
        this.props.dispatchSetTabId(index)

    }
    render(){
      return (
      <Switch>
        <Route path='/call'>
          <CallPage />
        </Route>
        <Route path='/send'>
        <SendPage />
        </Route>
        <Route path='/returnhome' >
        <ReturnPage />
        </Route>
        <Route path='/' >
          <TopPage {...this.props}/>
        </Route>
      </Switch>)
    }
}
const mapStateToProps = state => {
    return { 
    };
  };
const mapDispatchToProps = dispatch=>({
    dispatchSetTabId:tabIndex=>dispatch(setTabIndex(tabIndex)),
  })
export default connect(mapStateToProps,mapDispatchToProps)(OneTripPage)