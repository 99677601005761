import QuestionIcon from './icons/QuestionIcon';
import './Question.css'
function Question(props){
    return(
        <div className="question-frame">
            <QuestionIcon className="question-icon"/>
            <div className="question-label" onClick={props.handleOpen}>{props.children}</div>
        </div>
    )

}

export default Question