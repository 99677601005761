import {IS_LOGGED_IN,ENABLE_RUN_VIEW} from '../actions/userAction'

const initialState ={
    is_LoggedIn : false,
    enableRunView:false,
}
export default function userReducer(state = initialState,action = {}){
    switch(action.type){
        case IS_LOGGED_IN:
            return Object.assign({},state,{is_LoggedIn : action.is_LoggedIn});
        case ENABLE_RUN_VIEW:
            return Object.assign({},state,{enableRunView : action.enableRunView});
        default:
            return state;
    }
}
