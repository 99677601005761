import React from 'react';
import { connect } from 'react-redux';
import './CallConfirmPage.css';
import { withRouter } from 'react-router';
import GreenButton from '../../components/buttons/GreenButton';
import BackButton from '../../components/BackButton';
import VehicleIcon from '../../components/icons/VehicleIcon';
import EnableArrowIcon from '../../components/icons/EnableArrowIcon';
import DisableArrowIcon from '../../components/icons/DisableArrowIcon';
import EnableTargetPoint from '../../components/TargetPoints/EnableTargetPoint';
import DisableTargetPoint from '../../components/TargetPoints/DisableTargetPoint';

class CallConfirmPage extends React.Component{

    constructor(props){
        super(props)
        this.handleBack = this.handleBack.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }
    
    componentDidMount(){
        if(this.props.destinationPointId < 0 || this.props.pointId < 0){
            this.props.history.push("/call")
        }
    }

    handleBack(){
        this.props.history.push("/call")
    }

    handleNext(){
        this.props.handleSetApiExecFlg(false)
        this.props.history.push({pathname:"/call/complete",state:{ref:"/call/confirm"}})
    }
    
    getPointName(pointList, pointId){
        var pointName = ""
        for(var i=0;i<pointList.length;i++){
            if(pointList[i]["point_id"] == pointId){
                pointName =pointList[i]["name"] 
            }
        }
        return pointName
    }
    render(){
        const title = window.innerWidth < 1024 ? <>この経路で空き車両を<br/>呼び出しますか？</>: <>この経路で空き車両を呼び出しますか？</> 
        
        return(
            <div className="call-confirm-root content-root">
                <div className="call-confirm-title">
                    <BackButton handleBack={this.handleBack}/>
                    <div className="call-confirm-text-frame">
                        <div className="call-confirm-text">{title}</div>
                    </div>
                </div>
                <div className="call-confirm-content-frame">
                    <VehicleIcon className="call-vehicle-icon"/>
                    <EnableArrowIcon className="call-arrow-icon"/>
                    <EnableTargetPoint targetLabel="呼び出す場所" >{this.getPointName(this.props.pointList,this.props.pointId)}</EnableTargetPoint>
                    <DisableArrowIcon className="call-arrow-icon"/>
                    <DisableTargetPoint targetLabel="行き先">{this.getPointName(this.props.pointList,this.props.destinationPointId)}</DisableTargetPoint>
                </div>
                <GreenButton buttonName="呼び出す" onClick={this.handleNext}/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      pointList:state.systemReducer.pointList,
      pointId:state.systemReducer.pointId,
    };
  };
export default connect(mapStateToProps,null)(withRouter(CallConfirmPage));